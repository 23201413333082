import { supabase } from '../supabase';

interface ContactMessage {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export async function submitContactForm(data: ContactMessage) {
  try {
    const { error } = await supabase
      .from('contact_messages')
      .insert([{
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        status: 'new'
      }]);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
}