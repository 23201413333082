import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar } from 'lucide-react';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';

export const Footer: React.FC = () => {
  const { scrollToTop } = useSmoothScroll();

  return (
    <footer className="bg-primary text-white mt-auto">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2">
            <div className="flex items-center mb-4">
              <Calendar className="h-8 w-8 text-accent-red mr-2" />
              <h2 className="text-2xl font-bold">EVENTS</h2>
            </div>
            <p className="text-gray-300 text-sm">
              Share, connect, and experience amazing events in your community.
              Join us in creating unforgettable moments together.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link 
                  to="/privacy" 
                  onClick={scrollToTop}
                  className="text-gray-300 hover:text-white transition-colors"
                  role="link"
                  aria-label="Privacy Policy"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/cookies" 
                  onClick={scrollToTop}
                  className="text-gray-300 hover:text-white transition-colors"
                  role="link"
                  aria-label="Cookie Policy"
                >
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/terms" 
                  onClick={scrollToTop}
                  className="text-gray-300 hover:text-white transition-colors"
                  role="link"
                  aria-label="Terms of Service"
                >
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link 
                  to="/about" 
                  onClick={scrollToTop}
                  className="text-gray-300 hover:text-white transition-colors"
                  role="link"
                  aria-label="About Us"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link 
                  to="/contact" 
                  onClick={scrollToTop}
                  className="text-gray-300 hover:text-white transition-colors"
                  role="link"
                  aria-label="Contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-700">
          <p className="text-center text-gray-300 text-sm">
            © {new Date().getFullYear()} Events. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};