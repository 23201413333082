import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { 
  User, 
  LogOut, 
  LayoutDashboard, 
  UserX,
  Settings,
  Calendar,
  Shield,
  PlusCircle
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { DeleteAccountDialog } from '../dialogs/DeleteAccountDialog';

interface AvatarMenuProps {
  setShowForm?: (show: boolean) => void;
}

export const AvatarMenu: React.FC<AvatarMenuProps> = ({ setShowForm }) => {
  const { user, signOut } = useAuth();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const isAdmin = user?.user_metadata?.is_admin;

  if (!user) return null;

  const handleSignOut = async (e: React.MouseEvent) => {
    e.preventDefault();
    await signOut();
  };

  const menuItems = [
    {
      label: 'Profile',
      icon: <User className="w-4 h-4" />,
      href: '/profile',
      color: 'text-blue-600'
    },
    ...(isAdmin ? [{
      label: 'Admin Dashboard',
      icon: <Shield className="w-4 h-4" />,
      href: '/admin',
      color: 'text-purple-600'
    }] : []),
    {
      label: 'Dashboard',
      icon: <LayoutDashboard className="w-4 h-4" />,
      href: '/dashboard',
      color: 'text-green-600',
      onClick: () => setShowForm?.(false)
    },
    {
      label: 'My Events',
      icon: <Calendar className="w-4 h-4" />,
      href: '/dashboard',
      color: 'text-purple-600',
      onClick: () => setShowForm?.(false)
    },
    {
      label: 'Post Event',
      icon: <PlusCircle className="w-4 h-4" />,
      href: '/',
      color: 'text-green-600',
      onClick: () => setShowForm?.(true)
    },
    {
      label: 'Settings',
      icon: <Settings className="w-4 h-4" />,
      href: '/profile',
      color: 'text-gray-600'
    },
    {
      label: 'Privacy',
      icon: <Shield className="w-4 h-4" />,
      href: '/privacy',
      color: 'text-indigo-600'
    }
  ];

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button className="flex items-center p-1.5 rounded-full text-white hover:bg-white/10 transition-colors focus:outline-none focus:ring-2 focus:ring-white/20">
          {user.user_metadata.avatar_url ? (
            <img
              src={user.user_metadata.avatar_url}
              alt={user.user_metadata.full_name || 'User avatar'}
              className="h-8 w-8 rounded-full object-cover ring-2 ring-white/10"
            />
          ) : (
            <div className="h-8 w-8 rounded-full bg-accent-red/10 flex items-center justify-center ring-2 ring-white/10">
              <User className="h-5 w-5 text-white" />
            </div>
          )}
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-xl bg-white dark:bg-dark-surface-primary py-3 shadow-lg ring-1 ring-black/5 dark:ring-white/10 focus:outline-none divide-y divide-gray-100 dark:divide-gray-800">
            <div className="px-4 py-3 dark:bg-dark-surface-secondary/50">
              <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                {user.user_metadata.username || user.user_metadata.full_name || 'User'}
              </p>
              <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                {user.email}
              </p>
            </div>

            <div className="py-2">
              {menuItems.map((item) => (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <Link
                      to={item.href}
                      onClick={item.onClick}
                      className={`
                        ${active ? 'bg-gray-50 dark:bg-dark-surface-secondary' : ''}
                        flex w-full items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 group cursor-pointer hover:text-gray-900 dark:hover:text-white transition-colors
                      `}
                    >
                      <span className={`mr-3 ${item.color}`}>
                        {item.icon}
                      </span>
                      {item.label}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>

            <div className="py-2">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowDeleteDialog(true)}
                    className={`
                      ${active ? 'bg-red-50 dark:bg-red-900/20' : ''}
                      flex w-full items-center px-4 py-2 text-sm text-red-600 dark:text-red-400 group hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors
                    `}
                  >
                    <UserX className="w-4 h-4 mr-3" />
                    Delete Account
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleSignOut}
                    className={`
                      ${active ? 'bg-gray-50 dark:bg-dark-surface-secondary' : ''}
                      flex w-full items-center px-4 py-2 text-sm text-red-600 dark:text-red-400 group hover:bg-gray-50 dark:hover:bg-dark-surface-secondary transition-colors
                    `}
                  >
                    <LogOut className="w-4 h-4 mr-3" />
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      
      <DeleteAccountDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
    </>
  );
};