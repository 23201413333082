import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X } from 'lucide-react';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, className = '' }) => {
  const navigate = useNavigate();

  const handleFocus = useCallback(() => {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className={`relative flex-1 ${className}`}>
      <div className="absolute inset-y-0 left-0 pl-2.5 flex items-center pointer-events-none">
        <Search className="h-4 w-4 text-white/60" />
      </div>
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={handleFocus}
        placeholder="Search events..."
        className="w-full pl-9 pr-9 py-1.5 text-sm bg-white/10 border border-white/10 rounded-lg 
                 text-white placeholder-white/60
                 focus:outline-none focus:ring-2 focus:ring-white/20 focus:border-transparent
                 transition-colors duration-200"
      />
      {value && (
        <button
          onClick={() => onChange('')}
          className="absolute inset-y-0 right-0 pr-2.5 flex items-center text-white/60 hover:text-white"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};