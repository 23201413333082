import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export const Terms: React.FC = () => {
  return (
    <div className="min-h-screen bg-primary-light dark:bg-dark-bg-primary py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/" className="inline-flex items-center text-accent-red hover:text-red-700 mb-8">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-white dark:bg-dark-surface-primary rounded-2xl shadow-xl p-8">
          <h1 className="text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent mb-6">Terms of Service</h1>
          
          <div className="prose dark:prose-invert max-w-none">
            <h2>Acceptance of Terms</h2>
            <p>
              By accessing and using this platform, you accept and agree to be bound by the
              terms and conditions of this agreement.
            </p>

            <h2>User Responsibilities</h2>
            <p>You agree to:</p>
            <ul>
              <li>Provide accurate information when creating events</li>
              <li>Respect other users and their content</li>
              <li>Not engage in harmful or malicious behavior</li>
              <li>Comply with all applicable laws and regulations</li>
            </ul>

            <h2>Content Guidelines</h2>
            <p>
              All content posted must be appropriate, legal, and respect intellectual
              property rights. We reserve the right to remove any content that violates
              these terms.
            </p>

            <h2>Limitation of Liability</h2>
            <p>
              We are not responsible for any damages or losses resulting from your use
              of our platform or any events you attend through our platform.
            </p>

            <h2>Changes to Terms</h2>
            <p>
              We reserve the right to modify these terms at any time. Continued use of
              the platform constitutes acceptance of any changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};