import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { HeaderButtons } from './HeaderButtons';
import { ThemeToggle } from '../ThemeToggle';
import { Confetti } from '../Confetti';
import { AvatarMenu } from './AvatarMenu';
import { SearchInput } from './SearchInput';
import { CalendarModal } from '../calendar/CalendarModal';

interface HeaderProps {
  showForm: boolean;
  setShowForm: (show: boolean) => void;
}

export const Header: React.FC<HeaderProps> = ({
  showForm,
  setShowForm,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('search') || '');
  const navigate = useNavigate();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    const currentQuery = searchParams.get('search') || '';
    setSearchQuery(currentQuery);
  }, [searchParams]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    
    if (value) {
      setSearchParams({ search: value });
    } else {
      setSearchParams({});
    }
    
    if (window.location.pathname !== '/') {
      navigate('/?search=' + encodeURIComponent(value));
    }
  };

  return (
    <header className="bg-primary text-white sticky top-0 z-50 shadow-lg">
      <div className="max-w-7xl mx-auto py-2 px-2 sm:py-4 sm:px-6 lg:px-8">
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center relative flex-shrink-0">
              <Calendar className="h-6 w-6 sm:h-8 sm:w-8 text-accent-red mr-2" />
              <div>
                <Link to="/" className="text-xl sm:text-3xl font-black tracking-tight hover:text-gray-100 transition-colors">
                  EVENTS
                </Link>
                <div className="flex items-center space-x-1 sm:space-x-2 text-[10px] sm:text-sm mt-0.5">
                  <span className="text-accent-blue">Share</span>
                  <span className="text-accent-blue">•</span>
                  <Link to="/profiles" className="text-accent-blue hover:text-blue-400 transition-colors">Connect</Link>
                  <span className="text-accent-blue">•</span>
                  <button
                    onClick={() => setIsCalendarOpen(true)}
                    className="text-accent-blue hover:text-blue-400 transition-colors"
                  >
                    Experience
                  </button>
                </div>
              </div>
              <Confetti />
            </div>

            <div className="flex items-center sm:hidden">
              <ThemeToggle />
              <HeaderButtons
                showForm={showForm}
                setShowForm={setShowForm}
              />
              <AvatarMenu setShowForm={setShowForm} />
            </div>
          </div>

          <div className="flex items-center justify-between gap-4 flex-1">
            <SearchInput 
              value={searchQuery}
              onChange={handleSearch}
              className="max-w-2xl"
            />

            <div className="hidden sm:flex items-center">
              <HeaderButtons
                showForm={showForm}
                setShowForm={setShowForm}
              />
              <ThemeToggle />
              <AvatarMenu setShowForm={setShowForm} />
            </div>
          </div>
        </div>
      </div>
      <CalendarModal isOpen={isCalendarOpen} onClose={() => setIsCalendarOpen(false)} />
    </header>
  );
};