import { SupabaseClient } from '@supabase/supabase-js';

export const checkTableExists = async (
  supabase: SupabaseClient,
  tableName: string
): Promise<boolean> => {
  const { error } = await supabase
    .from(tableName)
    .select('id')
    .limit(1);

  return !error || error.code !== '42P01';
};