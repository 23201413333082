import React from 'react';
import { Users, DollarSign, TrendingUp, Star, Filter } from 'lucide-react';
import { KPICard } from '../components/analytics/KPICard';
import { KPIGrid } from '../components/analytics/KPIGrid';

const kpiData = [
  { title: "Total Revenue", value: "$328,500", change: 12.5, icon: <DollarSign className="w-6 h-6 text-green-600" /> },
  { title: "User Growth", value: "2,845", change: 8.2, icon: <Users className="w-6 h-6 text-blue-600" /> },
  { title: "Conversion Rate", value: "3.2%", change: -2.1, icon: <TrendingUp className="w-6 h-6 text-purple-600" /> },
  { title: "Customer Satisfaction", value: "4.8", change: 0.4, icon: <Star className="w-6 h-6 text-yellow-600" /> }
];

export const Analytics: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Analytics Dashboard</h1>
          <button className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50">
            <Filter className="w-4 h-4 mr-2" />
            Filter
          </button>
        </div>

        {/* KPI Cards */}
        <KPIGrid data={kpiData} />

        {/* Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
          <div className="bg-white rounded-xl shadow-sm p-6 h-[400px]">
            <h3 className="text-gray-900 font-semibold mb-4">Revenue Trend</h3>
            <div className="h-[320px] flex items-center justify-center text-gray-500">
              Chart data loading...
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-sm p-6 h-[400px]">
            <h3 className="text-gray-900 font-semibold mb-4">Revenue by Category</h3>
            <div className="h-[320px] flex items-center justify-center text-gray-500">
              Chart data loading...
            </div>
          </div>
          <div className="bg-white rounded-xl shadow-sm p-6 h-[400px]">
            <h3 className="text-gray-900 font-semibold mb-4">Customer Segments</h3>
            <div className="h-[320px] flex items-center justify-center text-gray-500">
              Chart data loading...
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};