import { supabase } from '../supabase';
import { createProfilesSchema } from '../database/schema/profiles';
import { createProfileTriggers } from '../database/triggers/profiles';
import { checkTableExists } from '../database/utils/checkTable';

export async function initializeDatabase() {
  try {
    const profilesExist = await checkTableExists(supabase, 'profiles');

    if (!profilesExist) {
      await createProfilesSchema(supabase);
      await createProfileTriggers(supabase);
    }

    console.log('Database schema initialized successfully');
    return true;
  } catch (error) {
    console.error('Error initializing database:', error);
    return false;
  }
}