import React from 'react';
import { Activity, Cpu, HardDrive, CircuitBoard } from 'lucide-react';

const metrics = [
  {
    label: 'CPU Usage',
    value: '45%',
    icon: Cpu,
    color: 'text-blue-600',
    progress: 45
  },
  {
    label: 'Memory Usage',
    value: '62%',
    icon: CircuitBoard,
    color: 'text-purple-600',
    progress: 62
  },
  {
    label: 'Disk Space',
    value: '78%',
    icon: HardDrive,
    color: 'text-orange-600',
    progress: 78
  },
  {
    label: 'Network',
    value: '32%',
    icon: Activity,
    color: 'text-green-600',
    progress: 32
  }
];

export const SystemHealth: React.FC = () => {
  return (
    <div className="bg-white dark:bg-dark-surface-primary rounded-xl shadow-sm border border-gray-200 dark:border-dark-border-primary p-6">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
        System Health
      </h2>
      
      <div className="space-y-6">
        {metrics.map((metric) => {
          const Icon = metric.icon;
          
          return (
            <div key={metric.label}>
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <Icon className={`w-5 h-5 ${metric.color}`} />
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    {metric.label}
                  </span>
                </div>
                <span className="text-sm font-medium text-gray-900 dark:text-white">
                  {metric.value}
                </span>
              </div>
              
              <div className="h-2 bg-gray-100 dark:bg-dark-surface-secondary rounded-full overflow-hidden">
                <div
                  className={`h-full ${metric.color} bg-current transition-all duration-500`}
                  style={{ width: metric.value }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};