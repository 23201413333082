import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../context/ToastContext';

export const ProtectedAdminRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useAuth();
  const { showToast } = useToast();
  
  if (loading) {
    return (
      <div className="min-h-screen bg-primary-light flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-accent-red border-t-transparent mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    showToast('Please sign in to access admin dashboard');
    return <Navigate to="/auth" replace />;
  }

  if (!user.user_metadata?.is_admin) {
    console.log('User metadata:', user.user_metadata); // Debug log
    showToast('Admin access required. Please sign in with admin credentials.');
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};