import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface CollapsibleAddressProps {
  street: string;
  city: string;
  state: string;
  zip: string;
}

export const CollapsibleAddress: React.FC<CollapsibleAddressProps> = ({
  street,
  city,
  state,
  zip
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Only render if we have all address fields
  if (!street || !city || !state || !zip) return null;

  return (
    <div className="mt-1">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-accent-red hover:text-red-700 text-xs inline-flex items-center"
        aria-expanded={isExpanded}
        aria-controls="address-details"
      >
        {isExpanded ? (
          <>
            <ChevronUp className="w-3 h-3 mr-1" />
            Hide full address
          </>
        ) : (
          <>
            <ChevronDown className="w-3 h-3 mr-1" />
            See full address
          </>
        )}
      </button>

      <div
        id="address-details"
        className={`mt-1 text-xs text-gray-600 space-y-0.5 overflow-hidden transition-all duration-200 ${
          isExpanded ? 'max-h-20 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <p>{street}</p>
        <p>{city}, {state} {zip}</p>
      </div>
    </div>
  );
};