import { SupabaseClient } from '@supabase/supabase-js';

export const createProfileTriggers = async (supabase: SupabaseClient) => {
  const sql = `
    -- Create function to handle updated_at
    create or replace function handle_updated_at()
    returns trigger
    language plpgsql
    as $$
    begin
      new.updated_at = now();
      return new;
    end;
    $$;

    -- Create trigger for updated_at
    drop trigger if exists handle_profiles_updated_at on public.profiles;
    create trigger handle_profiles_updated_at
      before update on public.profiles
      for each row
      execute function handle_updated_at();

    -- Create function to handle new user registration
    create or replace function handle_new_user()
    returns trigger
    language plpgsql
    security definer
    set search_path = public
    as $$
    begin
      insert into public.profiles (id, full_name)
      values (new.id, new.raw_user_meta_data->>'full_name')
      on conflict (id) do nothing;
      return new;
    end;
    $$;

    -- Create trigger for new user registration
    drop trigger if exists on_auth_user_created on auth.users;
    create trigger on_auth_user_created
      after insert on auth.users
      for each row execute function handle_new_user();
  `;

  const { error } = await supabase.rpc('exec_sql', { sql });
  if (error) throw error;
};