import React, { useEffect, useRef } from 'react';
import confetti from 'canvas-confetti';

interface ConfettiProps {
  trigger?: boolean;
}

export const Confetti: React.FC<ConfettiProps> = ({ trigger = false }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const fireConfetti = (force = false) => {
    const button = buttonRef.current;
    if (!button || (!trigger && !force)) return;

    const rect = button.getBoundingClientRect();
    const x = (rect.left + rect.width / 2) / window.innerWidth;
    const y = (rect.top + rect.height / 2) / window.innerHeight;

    confetti({
      particleCount: 150,
      spread: 70,
      origin: { x, y },
      colors: ['#e63946', '#457b9d', '#1d3557'],
      angle: 90,
      startVelocity: 30,
      gravity: 0.8,
      ticks: 200
    });

    // Add a second burst for more celebration
    setTimeout(() => {
      confetti({
        particleCount: 75,
        spread: 50,
        origin: { x, y },
        colors: ['#e63946', '#457b9d', '#1d3557'],
        angle: 120,
        startVelocity: 25,
        gravity: 0.7,
        ticks: 175
      });
    }, 200);
  };

  useEffect(() => {
    if (trigger) {
      fireConfetti();
    }
  }, [trigger]);
  
  return trigger ? (
    <div className="fixed inset-0 pointer-events-none z-50">
      <button
        ref={buttonRef}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-8 h-8 opacity-0"
        aria-hidden="true"
      />
    </div>
  ) : null;
};