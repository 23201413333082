import React, { useState } from 'react';
import { Auth as SupabaseAuth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../lib/supabase';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { PasswordStrengthIndicator } from './auth/PasswordStrengthIndicator';
import { useToast } from '../context/ToastContext'; 

export function Auth() {
  const { user } = useAuth();
  const [password, setPassword] = useState('');
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const [view, setView] = useState<'sign_in' | 'sign_up'>('sign_in'); 
  const { showToast } = useToast();
  const [adminCode, setAdminCode] = useState('');

  // If user is already logged in, redirect to home
  if (user) {
    return <Navigate to="/" replace />;
  }

  const handleViewChange = (newView: 'sign_in' | 'sign_up') => {
    setView(newView);
    setPassword('');
    setIsPasswordStrong(false);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <div className="min-h-screen bg-primary-light dark:bg-dark-bg-primary flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white dark:bg-dark-surface-primary rounded-2xl shadow-xl p-6 sm:p-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-primary dark:text-white text-center mb-6 sm:mb-8">
          {view === 'sign_in' ? 'Welcome Back' : 'Create Account'}
        </h2>
        <div className="flex justify-center space-x-4 mb-6">
          <button
            onClick={() => handleViewChange('sign_in')}
            className={`text-sm font-medium px-4 py-2 rounded-lg transition-colors ${
              view === 'sign_in'
                ? 'bg-accent-red text-white'
                : 'text-gray-600 hover:text-accent-red'
            }`}
          >
            Sign In
          </button>
          <button
            onClick={() => handleViewChange('sign_up')}
            className={`text-sm font-medium px-4 py-2 rounded-lg transition-colors ${
              view === 'sign_up'
                ? 'bg-accent-red text-white'
                : 'text-gray-600 hover:text-accent-red'
            }`}
          >
            Sign Up
          </button>
        </div>
        {view === 'sign_in' && (
          <div className="mb-4">
            <input
              type="text"
              value={adminCode}
              onChange={(e) => setAdminCode(e.target.value)}
              placeholder="Admin Code (optional)"
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-accent-red focus:border-accent-red"
            />
          </div>
        )}
        <SupabaseAuth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: '#e63946',
                  brandAccent: '#dc2f3c',
                }
              }
            },
            className: {
              container: 'supabase-container',
              button: 'supabase-button',
              input: 'supabase-input',
              label: 'block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1',
              message: 'text-sm text-red-600 mt-1',
              anchor: 'text-accent-red hover:text-red-700 font-medium',
            }
          }}
          localization={{
            variables: {
              sign_up: {
                password_input_label: 'Password',
                password_input_placeholder: 'Create a strong password',
                button_label: 'Sign up',
              }
            }
          }}
          providers={['google', 'github']}
          redirectTo={window.location.origin}
          view={view}
          showLinks={true}
          onPasswordChange={handlePasswordChange}
          onSubmit={(e) => {
            if (view === 'sign_up' && !isPasswordStrong) {
              e.preventDefault();
              showToast('Please create a stronger password');
              return;
            }
            if (view === 'sign_in' && adminCode === 'ADMIN123') {
              e.preventDefault();
              const form = e.target as HTMLFormElement;
              supabase.auth.signInWithPassword({
                email: form.email.value,
                password: form.password.value
              }).then(({ data: { user: signedInUser }, error: signInError }) => {
                if (signInError) {
                  showToast(signInError.message);
                  return;
                }
                if (!signedInUser) {
                  showToast('No user returned after sign in');
                  return;
                }
                supabase.auth.updateUser({
                  data: {
                    is_admin: true,
                    admin_granted_at: new Date().toISOString()
                  }
                }).then(({ error: updateError }) => {
                  if (updateError) {
                    showToast(updateError.message);
                    return;
                  }
                  supabase.auth.refreshSession().then(({ error: refreshError }) => {
                    if (refreshError) {
                      showToast(refreshError.message);
                      return;
                    }
                    showToast('Admin access granted successfully');
                  });
                });
              });
            }
          }}
        />
        {view === 'sign_up' && (
          <div className="mt-4">
            <PasswordStrengthIndicator
              password={password}
              onStrengthChange={setIsPasswordStrong}
            />
          </div>
        )}
      </div>
    </div>
  );
}