export const categoryColors = {
  'Arts & Culture': {
    bg: 'bg-purple-500',
    hover: 'hover:bg-purple-600',
    text: 'text-purple-50',
    inactive: 'bg-purple-100 text-purple-700 hover:bg-purple-200'
  },
  'Business & Professional': {
    bg: 'bg-blue-500',
    hover: 'hover:bg-blue-600',
    text: 'text-blue-50',
    inactive: 'bg-blue-100 text-blue-700 hover:bg-blue-200'
  },
  'Community & Civic': {
    bg: 'bg-green-500',
    hover: 'hover:bg-green-600',
    text: 'text-green-50',
    inactive: 'bg-green-100 text-green-700 hover:bg-green-200'
  },
  'Education & Learning': {
    bg: 'bg-yellow-500',
    hover: 'hover:bg-yellow-600',
    text: 'text-yellow-50',
    inactive: 'bg-yellow-100 text-yellow-700 hover:bg-yellow-200'
  },
  'Fashion & Beauty': {
    bg: 'bg-pink-500',
    hover: 'hover:bg-pink-600',
    text: 'text-pink-50',
    inactive: 'bg-pink-100 text-pink-700 hover:bg-pink-200'
  },
  'Film & Media': {
    bg: 'bg-indigo-500',
    hover: 'hover:bg-indigo-600',
    text: 'text-indigo-50',
    inactive: 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
  },
  'Food & Drink': {
    bg: 'bg-orange-500',
    hover: 'hover:bg-orange-600',
    text: 'text-orange-50',
    inactive: 'bg-orange-100 text-orange-700 hover:bg-orange-200'
  },
  'Health & Wellness': {
    bg: 'bg-teal-500',
    hover: 'hover:bg-teal-600',
    text: 'text-teal-50',
    inactive: 'bg-teal-100 text-teal-700 hover:bg-teal-200'
  },
  'Hobbies & Special Interest': {
    bg: 'bg-cyan-500',
    hover: 'hover:bg-cyan-600',
    text: 'text-cyan-50',
    inactive: 'bg-cyan-100 text-cyan-700 hover:bg-cyan-200'
  },
  'Music & Performance': {
    bg: 'bg-rose-500',
    hover: 'hover:bg-rose-600',
    text: 'text-rose-50',
    inactive: 'bg-rose-100 text-rose-700 hover:bg-rose-200'
  },
  'Science & Technology': {
    bg: 'bg-sky-500',
    hover: 'hover:bg-sky-600',
    text: 'text-sky-50',
    inactive: 'bg-sky-100 text-sky-700 hover:bg-sky-200'
  },
  'Sports & Fitness': {
    bg: 'bg-emerald-500',
    hover: 'hover:bg-emerald-600',
    text: 'text-emerald-50',
    inactive: 'bg-emerald-100 text-emerald-700 hover:bg-emerald-200'
  },
  'Travel & Outdoor': {
    bg: 'bg-lime-500',
    hover: 'hover:bg-lime-600',
    text: 'text-lime-50',
    inactive: 'bg-lime-100 text-lime-700 hover:bg-lime-200'
  }
} as const;