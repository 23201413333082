import React from 'react';
import { LogIn } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

interface HeaderButtonsProps {
  showForm: boolean;
  setShowForm: (show: boolean) => void;
}

export const HeaderButtons: React.FC<HeaderButtonsProps> = ({
  showForm,
  setShowForm,
}) => {
  const { user } = useAuth();

  return (
    <div className="flex items-center space-x-1.5 sm:space-x-3">
      {!user && (
        <Link
          to="/auth"
          className="inline-flex items-center px-1.5 sm:px-3 py-1 bg-accent-red text-white text-xs font-medium rounded hover:bg-red-700 transition-colors"
        >
          <LogIn className="w-3 h-3 sm:w-3.5 sm:h-3.5 sm:mr-1" />
          <span className="hidden sm:inline">Sign In</span>
        </Link>
      )}
    </div>
  );
};