import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export const Privacy: React.FC = () => {
  return (
    <div className="min-h-screen bg-primary-light dark:bg-dark-bg-primary py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/" className="inline-flex items-center text-accent-red hover:text-red-700 mb-8">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-white dark:bg-dark-surface-primary rounded-2xl shadow-xl p-8">
          <h1 className="text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent mb-6">Privacy Policy</h1>
          
          <div className="prose dark:prose-invert max-w-none">
            <h2>Information We Collect</h2>
            <p>
              We collect information you provide directly to us when you create an account,
              post events, or interact with other users on our platform.
            </p>

            <h2>How We Use Your Information</h2>
            <p>
              We use the information we collect to provide, maintain, and improve our services,
              to communicate with you, and to personalize your experience.
            </p>

            <h2>Information Sharing</h2>
            <p>
              We do not sell or share your personal information with third parties except
              as described in this policy or with your consent.
            </p>

            <h2>Data Security</h2>
            <p>
              We implement appropriate technical and organizational measures to protect
              your personal information against unauthorized access or disclosure.
            </p>

            <h2>Your Rights</h2>
            <p>
              You have the right to access, correct, or delete your personal information.
              You can also object to or restrict certain processing of your information.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at
              privacy@events.com.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};