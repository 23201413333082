import { supabase } from '../supabase';

interface ProfileData {
  username?: string;
  full_name?: string;
  age?: number;
  gender?: string;
  avatar_url?: string;
  bio?: string;
  location?: string;
  date_of_birth?: string;
  privacy_settings?: {
    profile_visibility: 'public' | 'private';
    email_visibility: 'public' | 'private';
  };
}

export async function getProfile(userId: string) {
  try {
    // First try to get existing profile
    const { data: profile, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .maybeSingle();

    if (error && error.code !== 'PGRST116') {
      throw error;
    }

    // If no profile exists, create one with minimal data
    if (!profile) {
      const { data: userData } = await supabase.auth.getUser();
      if (!userData.user) throw new Error('No authenticated user');

      const { data: newProfile, error: createError } = await supabase
        .from('profiles')
        .insert([{
          id: userId,
          full_name: userData.user.user_metadata?.full_name || '',
          privacy_settings: {
            profile_visibility: 'public',
            email_visibility: 'private'
          }
        }])
        .select()
        .single();

      if (createError) throw createError;
      return newProfile;
    }

    return profile;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
}

export async function updateProfile(userId: string, profile: ProfileData) {
  try {
    const updates = {
      ...profile,
      updated_at: new Date().toISOString()
    };

    const { error } = await supabase
      .from('profiles')
      .upsert({
        id: userId,
        ...updates
      }, {
        onConflict: 'id'
      });

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
}

export async function checkUsernameAvailability(username: string): Promise<boolean> {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('username')
      .ilike('username', username)
      .maybeSingle();

    if (error && error.code !== 'PGRST116') {
      throw error;
    }

    // Username is available if no match found
    return !data;
  } catch (error) {
    console.error('Error checking username:', error);
    throw error;
  }
}