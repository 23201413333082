import React from 'react';
import { EventList } from '../EventList';
import type { Event } from '../../types/event';

interface EventListSectionProps {
  loading: boolean;
  events: Event[];
  searchQuery: string;
  onEventUpdate: (event: Event) => Promise<void>;
}

export const EventListSection: React.FC<EventListSectionProps> = ({
  loading,
  events,
  searchQuery,
  onEventUpdate,
}) => {
  return (
    <div className="mb-3 sm:mb-6 bg-white dark:bg-dark-surface-primary rounded-lg sm:rounded-xl p-3 sm:p-6 shadow-sm border border-gray-200 dark:border-dark-border-primary">
      <div className="flex items-center justify-between mb-4 sm:mb-6">
        <h2 className="text-xl sm:text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">
          {searchQuery ? `Search Results for "${searchQuery}"` : 'Upcoming Events'}
        </h2>
      </div>
      
      {loading ? (
        <div className="text-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-accent-red border-t-transparent mx-auto"></div>
          <p className="mt-4 text-gray-600 dark:text-gray-400">Loading events...</p>
        </div>
      ) : events.length === 0 ? (
        <div className="text-center py-8 sm:py-12 bg-white dark:bg-dark-surface-primary rounded-lg sm:rounded-xl shadow-md mt-4 sm:mt-6 border border-gray-200 dark:border-dark-border-primary">
          <h3 className="text-xl font-semibold text-primary dark:text-dark-text-primary">
            {searchQuery ? 'No matching events found' : 'No events yet'}
          </h3>
          <p className="mt-2 text-gray-500 dark:text-gray-400">
            {searchQuery ? 'Try adjusting your search terms' : 'Be the first to share an event with the community.'}
          </p>
        </div>
      ) : (
        <EventList events={events} onEventUpdate={onEventUpdate} />
      )}
    </div>
  );
};