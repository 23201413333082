import React from 'react';
import { User, Shield, Settings, Database } from 'lucide-react';

const activities = [
  {
    id: 1,
    type: 'user',
    action: 'User account created',
    details: 'New user "john.doe" registered',
    time: '2 minutes ago',
    icon: User,
    color: 'text-blue-600',
    bg: 'bg-blue-100'
  },
  {
    id: 2,
    type: 'security',
    action: 'Security alert',
    details: 'Failed login attempt detected',
    time: '15 minutes ago',
    icon: Shield,
    color: 'text-red-600',
    bg: 'bg-red-100'
  },
  {
    id: 3,
    type: 'system',
    action: 'System update',
    details: 'Database backup completed',
    time: '1 hour ago',
    icon: Database,
    color: 'text-green-600',
    bg: 'bg-green-100'
  },
  {
    id: 4,
    type: 'settings',
    action: 'Settings changed',
    details: 'Email configuration updated',
    time: '2 hours ago',
    icon: Settings,
    color: 'text-purple-600',
    bg: 'bg-purple-100'
  }
];

export const RecentActivity: React.FC = () => {
  return (
    <div className="bg-white dark:bg-dark-surface-primary rounded-xl shadow-sm border border-gray-200 dark:border-dark-border-primary p-6">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
        Recent Activity
      </h2>
      
      <div className="space-y-4">
        {activities.map((activity) => {
          const Icon = activity.icon;
          
          return (
            <div
              key={activity.id}
              className="flex items-start space-x-4 p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-dark-surface-secondary transition-colors"
            >
              <div className={`p-2 ${activity.bg} rounded-lg`}>
                <Icon className={`w-5 h-5 ${activity.color}`} />
              </div>
              
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {activity.action}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                  {activity.details}
                </p>
                <p className="text-xs text-gray-400 dark:text-gray-500 mt-1">
                  {activity.time}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};