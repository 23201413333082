import React, { useState } from 'react';
import { ChevronDown, ChevronUp, AlertCircle } from 'lucide-react';
import sanitizeHtml from 'sanitize-html';

interface ExpandableDescriptionProps {
  text: string;
  maxLength: number;
  onChange?: (text: string) => void;
  editable?: boolean;
}

export const ExpandableDescription: React.FC<ExpandableDescriptionProps> = ({
  text,
  maxLength,
  onChange,
  editable = false
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentText, setCurrentText] = useState(text);
  const remainingChars = maxLength - currentText.length;

  const cleanText = sanitizeHtml(currentText, {
    allowedTags: [],
    allowedAttributes: {}
  });

  const shouldTruncate = cleanText.length > maxLength;
  const displayText = !isExpanded && shouldTruncate 
    ? cleanText.slice(0, maxLength) + '...'
    : cleanText;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    if (newText.length <= maxLength) {
      setCurrentText(newText);
      onChange?.(newText);
    }
  };

  return (
    <div className="mb-0">
      {editable ? (
        <div className="relative">
          <textarea
            value={currentText}
            onChange={handleChange}
            maxLength={maxLength}
            rows={3}
            className="w-full px-3 py-2 text-sm text-gray-700 border rounded-lg resize-none
              focus:ring-2 focus:ring-accent-red focus:border-accent-red"
            placeholder="Enter event description..."
          />
          <div className="absolute bottom-2 right-2 flex items-center space-x-2 text-xs">
            {remainingChars <= 20 && (
              <AlertCircle className={`w-3 h-3 ${
                remainingChars === 0 ? 'text-red-500' : 'text-yellow-500'
              }`} />
            )}
            <span className={`font-medium ${
              remainingChars <= 10 ? 'text-red-500' : 'text-gray-500'
            }`}>
              {remainingChars}
            </span>
          </div>
        </div>
      ) : (
        <p className="text-gray-600 dark:text-gray-300 break-words whitespace-pre-wrap max-w-full bg-white/70 dark:bg-dark-surface-secondary/50 backdrop-blur-[2px] rounded-md p-2 border border-white/20 dark:border-white/10">
          {displayText}
        </p>
      )}
      {shouldTruncate && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-1 text-accent-red hover:text-red-700 text-sm font-medium inline-flex items-center transition-colors duration-200"
        >
          {isExpanded ? (
            <>
              Show Less
              <ChevronUp className="w-4 h-4 ml-1" />
            </>
          ) : (
            <>
              Read More
              <ChevronDown className="w-4 h-4 ml-1" />
            </>
          )}
        </button>
      )}
    </div>
  );
};