import React, { useState, useEffect } from 'react';
import { Layout } from '../../components/admin/Layout';
import { User, Shield, AlertTriangle } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { promoteToAdmin } from '../../lib/api/auth';
import { useToast } from '../../context/ToastContext';

interface UserData {
  id: string;
  email: string;
  created_at: string;
  user_metadata: {
    role?: string;
    full_name?: string;
  };
}

export const Users: React.FC = () => {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data: { users }, error } = await supabase.auth.admin.listUsers();
      if (error) throw error;
      setUsers(users);
    } catch (error) {
      console.error('Error fetching users:', error);
      showToast('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handlePromoteToAdmin = async (userId: string) => {
    try {
      setLoading(true);
      await promoteToAdmin(userId);
      showToast('User promoted to admin successfully');
      fetchUsers(); // Refresh user list
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to promote user to admin';
      showToast(message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-accent-red border-t-transparent" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">User Management</h1>
          <button className="px-4 py-2 bg-accent-red text-white rounded-lg hover:bg-red-700">
            Add User
          </button>
        </div>

        <div className="bg-white dark:bg-dark-surface-primary rounded-xl shadow-sm overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-dark-border-primary">
            <thead className="bg-gray-50 dark:bg-dark-surface-secondary">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Role
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Created At
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-dark-border-primary">
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <div className="h-10 w-10 rounded-full bg-accent-red/10 flex items-center justify-center">
                          <User className="h-5 w-5 text-accent-red" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {user.user_metadata.full_name || 'No name'}
                        </div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {user.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.user_metadata.role === 'admin'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-gray-100 text-gray-800'
                    }`}>
                      {user.user_metadata.role || 'user'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {new Date(user.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    {user.user_metadata.role !== 'admin' ? (
                      <button
                        onClick={() => handlePromoteToAdmin(user.id)}
                        className="text-accent-red hover:text-red-700 mr-4"
                      >
                        Promote to Admin
                      </button>
                    ) : (
                      <span className="text-green-600 flex items-center">
                        <Shield className="w-4 h-4 mr-1" />
                        Admin
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};