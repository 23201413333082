import { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../context/ToastContext';

export const useLikes = () => {
  const [likes, setLikes] = useState<Record<string, number>>({});
  const [userLikes, setUserLikes] = useState<Set<string>>(new Set());
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();
  const { showToast } = useToast();
  const pendingOps = useRef<Set<string>>(new Set());
  const retryCount = useRef(0);
  const maxRetries = 3;

  const fetchLikes = useCallback(async () => {
    try {
      setError(null);
      const { data, error } = await supabase
        .from('likes')
        .select('event_id, user_id');

      if (error) {
        throw error;
      }

      // Count likes per event
      const counts: Record<string, number> = {};
      data?.forEach(like => {
        counts[like.event_id] = (counts[like.event_id] || 0) + 1;
      });
      setLikes(counts);

      // Set user likes if authenticated
      if (user) {
        const userLikedEvents = new Set(
          data?.filter(like => like.user_id === user.id).map(like => like.event_id)
        );
        setUserLikes(userLikedEvents);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching likes:', error);
      setError(error instanceof Error ? error : new Error('Failed to fetch likes'));
      
      // Implement retry logic
      if (retryCount.current < maxRetries) {
        retryCount.current += 1;
        const delay = Math.min(1000 * Math.pow(2, retryCount.current), 10000);
        setTimeout(() => {
          fetchLikes();
        }, delay);
      } else {
        setIsLoading(false);
      }
      showToast('Failed to load likes');
    }
  }, [user, showToast]);

  useEffect(() => {
    fetchLikes();

    // Subscribe to likes changes
    const channel = supabase
      .channel('likes-changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'likes'
      }, (payload) => {
        // Skip if this is a pending operation
        const opKey = `${payload.new?.event_id}-${payload.new?.user_id}`;
        if (pendingOps.current.has(opKey)) {
          pendingOps.current.delete(opKey);
          return;
        }
        fetchLikes();
      })
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [fetchLikes]);

  const toggleLike = async (eventId: string) => {
    if (!user) {
      showToast('Please sign in to like events');
      return;
    }

    const opKey = `${eventId}-${user.id}`;
    pendingOps.current.add(opKey);

    try {
      if (userLikes.has(eventId)) {
        // Unlike
        setUserLikes(prev => {
          const next = new Set(prev);
          next.delete(eventId);
          return next;
        });
        setLikes(prev => ({
          ...prev,
          [eventId]: Math.max(0, (prev[eventId] || 1) - 1)
        }));

        const { error } = await supabase
          .from('likes')
          .delete()
          .eq('event_id', eventId)
          .eq('user_id', user.id);

        if (error) throw error;
      } else {
        // Like
        setUserLikes(prev => new Set([...prev, eventId]));
        setLikes(prev => ({
          ...prev,
          [eventId]: (prev[eventId] || 0) + 1
        }));

        const { error } = await supabase
          .from('likes')
          .insert([{ event_id: eventId, user_id: user.id }]);

        if (error && error.code !== '23505') throw error;
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      showToast('Failed to update like');
      // Revert optimistic updates on error
      if (userLikes.has(eventId)) {
        setUserLikes(prev => new Set([...prev, eventId]));
        setLikes(prev => ({
          ...prev,
          [eventId]: (prev[eventId] || 0) + 1
        }));
      } else {
        setUserLikes(prev => {
          const next = new Set(prev);
          next.delete(eventId);
          return next;
        });
        setLikes(prev => ({
          ...prev,
          [eventId]: Math.max(0, (prev[eventId] || 1) - 1)
        }));
      }
    } finally {
      pendingOps.current.delete(opKey);
    }
  };

  const getLikes = (eventId: string) => likes[eventId] || 0;
  const hasLiked = (eventId: string) => userLikes.has(eventId);

  return {
    toggleLike,
    getLikes,
    hasLiked,
    isLoading,
    error
  };
};