import React, { useState } from 'react';
import { format } from 'date-fns';
import { Calendar, MapPin, User, Tag, Edit2 } from 'lucide-react';
import type { Event } from '../types/event';
import { useLikes } from '../hooks/useLikes';
import { CalendarModal } from './calendar/CalendarModal';
import { useAuth } from '../context/AuthContext';
import { CollapsibleAddress } from './CollapsibleAddress';
import { EventHero } from './event/EventHero';
import { LikeButton } from './event/LikeButton';

interface EventCardProps {
  event: Event;
  onEventUpdate: (updatedEvent: Event) => void;
}

export const EventCard: React.FC<EventCardProps> = ({ event, onEventUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [editedEvent, setEditedEvent] = useState(event);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { toggleLike, getLikes, hasLiked, isLoading, error } = useLikes();
  const { user } = useAuth(); 
  const likeCount = getLikes(event.id);
  const liked = hasLiked(event.id);

  const handleSave = (e: React.MouseEvent) => {
    e.preventDefault();
    onEventUpdate(editedEvent);
    setIsEditing(false);
  };

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    setEditedEvent(event);
    setIsEditing(false);
  };

  const handleLike = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event bubbling
    toggleLike(event.id);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setEditedEvent(prev => ({
      ...prev,
      [name]: name === 'date' ? new Date(value) : value,
    }));
  };

  if (isEditing && user?.id === event.userId) {
    return (
      <div className="bg-white rounded-xl shadow-lg p-4 space-y-4">
        <input
          type="text"
          name="title"
          value={editedEvent.title}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg bg-white/80 dark:bg-dark-surface-secondary/60 backdrop-blur-[2px] border-white/20 dark:border-white/10 shadow-sm hover:bg-white/90 dark:hover:bg-dark-surface-secondary/70 transition-colors duration-200 focus:ring-2 focus:ring-accent-red/20 focus:border-accent-red"
        />
        <textarea
          name="description"
          value={editedEvent.description}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-lg min-h-[100px] bg-white/80 dark:bg-dark-surface-secondary/60 backdrop-blur-[2px] border-white/20 dark:border-white/10 shadow-sm hover:bg-white/90 dark:hover:bg-dark-surface-secondary/70 transition-colors duration-200 focus:ring-2 focus:ring-accent-red/20 focus:border-accent-red"
        />
        <div className="flex justify-end space-x-2">
          <button
            onClick={handleCancel}
            className="px-4 py-2 text-gray-600 bg-white/80 dark:bg-dark-surface-secondary/60 hover:bg-white/90 dark:hover:bg-dark-surface-secondary/70 rounded-lg backdrop-blur-[2px] border border-white/20 dark:border-white/10 shadow-sm transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-accent-red text-white rounded-lg hover:bg-red-700 transition-colors duration-200"
          >
            Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="relative group transform-gpu h-full">
      <div className="absolute inset-0 rounded-xl bg-gradient-to-b from-white/10 to-white/5 dark:from-white/5 dark:to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300" />
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className="relative overflow-hidden rounded-xl backdrop-blur-[12px] bg-gray-200/90 sm:bg-gray-50/90 dark:bg-dark-surface-primary/80 
        border border-accent-blue dark:border-white/20 shadow-lg transition-all duration-300 
        hover:bg-gray-50/95 dark:hover:bg-dark-surface-primary/90 group-hover:translate-y-[-2px]
        shadow-[0_8px_32px_0_rgba(31,38,135,0.07)] hover:shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]
        flex flex-col cursor-pointer">
        <div className="relative h-28">
          {event.imageUrl ? (
            <img
              src={event.imageUrl}
              alt={event.title}
              className="w-full h-full object-cover transition-all duration-300 group-hover:scale-105 group-hover:brightness-105"
            />
          ) : (
            <div className="w-full h-full bg-gradient-to-r from-accent-blue/80 via-accent-blue/60 to-accent-red/80 backdrop-blur-sm flex items-center justify-center">
              <Calendar className="w-6 h-6 text-white" />
            </div>
          )}
        </div>
        
        <div className="p-4 flex-1 flex flex-col">
          <div className="flex items-center justify-between mb-1">
            <span className="text-xs font-medium px-1.5 py-0.5 rounded-full bg-white/30 backdrop-blur-[2px] text-gray-700 flex items-center border border-white/40">
              <Tag className="w-3 h-3 mr-1 text-accent-red" />
              {event.category}
            </span>
            <LikeButton
              liked={liked}
              likeCount={likeCount}
              onLike={handleLike}
              disabled={!user}
              isLoading={isLoading}
            />
          </div>

          <h3 className="text-sm font-bold text-gray-800 dark:text-white mb-1 line-clamp-2">{event.title}</h3>
          
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-3 line-clamp-2">
            {event.description}
          </p>
          
          <div className="space-y-0.5">
            <div className="flex items-center text-gray-700 dark:text-gray-300 text-xs backdrop-blur-[2px]">
              <button
                onClick={() => setIsCalendarOpen(true)}
                className="p-1 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-full transition-colors mr-1.5 flex-shrink-0"
                aria-label="Open calendar"
              >
                <Calendar className="w-3.5 h-3.5 text-accent-red flex-shrink-0" />
              </button>
              <span className="truncate ml-0.5">{format(event.date, 'PPP p')}</span>
            </div>
            
            <div className="flex items-center text-gray-700 dark:text-gray-300 text-xs backdrop-blur-[2px]">
              <MapPin className="w-3.5 h-3.5 mr-1.5 text-accent-red flex-shrink-0" />
              <div>
                <span className="truncate">{event.location}</span>
                <CollapsibleAddress
                  street={event.addressStreet}
                  city={event.addressCity}
                  state={event.addressState}
                  zip={event.addressZip}
                />
              </div>
            </div>
            
            <div className="flex items-center text-gray-700 dark:text-gray-300 text-xs backdrop-blur-[2px]">
              <User className="w-3.5 h-3.5 mr-1.5 text-accent-red flex-shrink-0" />
              <span className="truncate">{event.organizer}</span>
            </div>
          </div>

        </div>
      </div>
      <CalendarModal isOpen={isCalendarOpen} onClose={() => setIsCalendarOpen(false)} />
      <EventHero
        event={event}
        isExpanded={isExpanded}
        onClose={() => setIsExpanded(false)}
      />
    </div>
  );
};