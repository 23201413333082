import React from 'react';
import { Calendar, MapPin, User, Tag, ThumbsUp } from 'lucide-react';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import type { Event } from '../../types/event';

interface EventHeroProps {
  event: Event;
  isExpanded: boolean;
  onClose: () => void;
}

export const EventHero: React.FC<EventHeroProps> = ({
  event,
  isExpanded,
  onClose,
}) => {
  return (
    <AnimatePresence>
      {isExpanded && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          className="overflow-hidden"
        >
          <div className="relative bg-white dark:bg-dark-surface-primary rounded-xl shadow-lg p-6 mt-4">
            <div className="absolute inset-0 bg-gradient-to-r from-accent-blue/10 to-accent-red/10 rounded-xl" />
            
            <div className="relative">
              {event.imageUrl ? (
                <img
                  src={event.imageUrl}
                  alt={event.title}
                  className="w-full h-64 object-cover rounded-lg mb-6"
                />
              ) : (
                <div className="w-full h-64 bg-gradient-to-r from-accent-blue to-accent-red rounded-lg mb-6 flex items-center justify-center">
                  <Calendar className="w-16 h-16 text-white" />
                </div>
              )}

              <div className="space-y-6">
                <div className="flex items-center justify-between">
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-accent-blue/10 text-accent-blue">
                    <Tag className="w-4 h-4 mr-1" />
                    {event.category}
                  </span>
                  <button
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                  >
                    Close
                  </button>
                </div>

                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  {event.title}
                </h2>

                <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                  {event.description}
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-3">
                    <div className="flex items-center text-gray-600 dark:text-gray-300">
                      <Calendar className="w-5 h-5 mr-2 text-accent-red" />
                      <span>{format(event.date, 'PPP p')}</span>
                    </div>
                    
                    <div className="flex items-center text-gray-600 dark:text-gray-300">
                      <User className="w-5 h-5 mr-2 text-accent-red" />
                      <span>{event.organizer}</span>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex items-start text-gray-600 dark:text-gray-300">
                      <MapPin className="w-5 h-5 mr-2 text-accent-red flex-shrink-0 mt-1" />
                      <div>
                        <p>{event.addressStreet}</p>
                        <p>{event.addressCity}, {event.addressState} {event.addressZip}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};