import React from 'react';
import { Tag } from 'lucide-react';
import { categories } from '../data/categories';
import { categoryColors } from '../data/categoryColors';

interface CategoryChipsProps {
  selectedCategory: string;
  onChange: (category: string) => void;
  className?: string;
}

export const CategoryChips: React.FC<CategoryChipsProps> = ({
  selectedCategory,
  onChange,
  className = ''
}) => {
  return (
    <div className={className}>
      <label className="flex items-center text-sm font-medium text-gray-700 mb-2">
        <Tag className="w-4 h-4 mr-1" />
        Category
      </label>
      <div className="flex flex-wrap gap-2">
        {categories.map(category => {
          const colors = categoryColors[category];
          const isSelected = selectedCategory === category;
          
          return (
            <button
              key={category}
              onClick={() => onChange(category)}
              className={`
                px-4 py-2 rounded-full text-sm font-medium
                transition-all duration-200 flex items-center gap-2
                transform hover:scale-105 hover:shadow-md
                ${isSelected 
                  ? `${colors.bg} ${colors.text} ${colors.hover} shadow-lg` 
                  : colors.inactive}
              `}
            >
              {category}
            </button>
          );
        })}
      </div>
    </div>
  );
};