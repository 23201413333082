import { useState, useEffect } from 'react';
import { createEvent, updateEvent as apiUpdateEvent, deleteEvent as apiDeleteEvent, getEvents } from '../lib/api/events';
import type { Event } from '../types/event';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../context/ToastContext';
import { categories } from '../data/categories';

export interface UseEventsReturn {
  events: Event[];
  loading: boolean;
  error: Error | null;
  addEvent: (eventData: Omit<Event, 'id' | 'userId'>) => Promise<Event>;
  updateEvent: (updatedEvent: Event) => Promise<void>;
  deleteEvent: (eventId: string) => Promise<void>;
}

const validateCategory = (category: string): string => {
  return categories.includes(category as any) ? category : 'Community & Civic';
};

export const useEvents = (): UseEventsReturn => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { user } = useAuth();
  const { showToast } = useToast();

  useEffect(() => {
    let mounted = true;

    const fetchEvents = async () => {
      try {
        setLoading(true);
        setError(null);
        const fetchedEvents = await getEvents();
        
        if (mounted) {
          setEvents(fetchedEvents.map(event => ({
            ...event,
            category: validateCategory(event.category)
          })));
        }
      } catch (error) {
        if (mounted) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to fetch events';
          setError(new Error(errorMessage));
          showToast(errorMessage);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchEvents();

    return () => {
      mounted = false;
    };
  }, [showToast]);

  const addEvent = async (eventData: Omit<Event, 'id' | 'userId'>) => {
    if (!user) {
      throw new Error('Please sign in to create an event');
    }

    try {
      const newEvent = await createEvent(eventData, user.id);
      setEvents(prev => [...prev, newEvent]);
      return newEvent;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to create event';
      throw new Error(errorMessage);
    }
  };

  const updateEvent = async (updatedEvent: Event) => {
    if (!user) {
      throw new Error('Please sign in to update events');
    }

    if (user.id !== updatedEvent.userId) {
      throw new Error('You can only update your own events');
    }

    try {
      await apiUpdateEvent(updatedEvent);
      setEvents(prev =>
        prev.map(event =>
          event.id === updatedEvent.id ? updatedEvent : event
        )
      );
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to update event';
      throw new Error(errorMessage);
    }
  };

  const deleteEvent = async (eventId: string) => {
    if (!user) {
      throw new Error('Please sign in to delete events');
    }

    try {
      await apiDeleteEvent(eventId, user.id);
      setEvents(prev => prev.filter(event => event.id !== eventId));
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to delete event';
      throw new Error(errorMessage);
    }
  };

  return {
    events,
    loading,
    error,
    addEvent,
    updateEvent,
    deleteEvent
  };
};