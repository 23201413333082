import { SupabaseClient } from '@supabase/supabase-js';

export const createProfilesSchema = async (supabase: SupabaseClient) => {
  const sql = `
    -- Drop existing profiles table if it exists
    drop table if exists public.profiles cascade;

    -- Create profiles table with proper structure
    create table if not exists public.profiles (
      id uuid primary key references auth.users on delete cascade,
      created_at timestamp with time zone default timezone('utc'::text, now()) not null,
      updated_at timestamp with time zone default timezone('utc'::text, now()) not null,
      username text unique,
      full_name text,
      avatar_url text,
      age integer check (age >= 13 and age <= 120),
      gender text check (gender in ('Male', 'Female', 'Non-binary', 'Prefer not to say')),
      bio text,
      location text,
      date_of_birth date,
      privacy_settings jsonb default '{"profile_visibility": "public", "email_visibility": "private"}'::jsonb
    );

    -- Create index for username lookups
    create index if not exists profiles_username_idx on public.profiles(username);

    -- Enable RLS
    alter table public.profiles enable row level security;

    -- Create RLS policies
    create policy "Public profiles are viewable by everyone"
      on public.profiles for select
      using (true);

    create policy "Users can insert their own profile"
      on public.profiles for insert
      with check (auth.uid() = id);

    create policy "Users can update their own profile"
      on public.profiles for update
      using (auth.uid() = id);

    -- Function to sync username with auth metadata
    create or replace function sync_username()
    returns trigger
    language plpgsql
    security definer
    set search_path = public
    as $$
    begin
      update auth.users
      set raw_user_meta_data = 
        raw_user_meta_data || 
        jsonb_build_object('username', new.username)
      where id = new.id;
      return new;
    end;
    $$;

    -- Create trigger for username sync
    drop trigger if exists on_username_update on public.profiles;
    create trigger on_username_update
      after update of username on public.profiles
      for each row
      when (old.username is distinct from new.username)
      execute function sync_username();
  `;

  const { error } = await supabase.rpc('exec_sql', { sql });
  if (error) throw error;
};