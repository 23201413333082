import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, Users } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { ProfileCard } from '../components/profile/ProfileCard';
import { useAuth } from '../context/AuthContext';

interface PublicProfile {
  id: string;
  username: string;
  full_name: string;
  avatar_url: string | null;
  bio: string | null;
  location: string | null;
  privacy_settings: {
    profile_visibility: 'public' | 'private';
    email_visibility: 'public' | 'private';
  };
}

export const Profiles: React.FC = () => {
  const [profiles, setProfiles] = useState<PublicProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        let query = supabase
          .from('profiles')
          .select('*')
          .eq('privacy_settings->>profile_visibility', 'public');
        
        if (user?.id) {
          query = query.neq('id', user.id);
        }

        const { data, error } = await query;

        if (error) throw error;
        setProfiles(data || []);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [user?.id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-primary-light flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-accent-red mx-auto" />
          <p className="mt-2 text-gray-600">Loading profiles...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-primary-light py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">
            Community Members
          </h1>
          <div className="flex items-center text-gray-600">
            <Users className="w-5 h-5 mr-2" />
            <span>{profiles.length} Public Profiles</span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {profiles.map((profile) => (
            <ProfileCard
              key={profile.id}
              userId={profile.id}
              fullName={profile.full_name}
              title={profile.username ? `@${profile.username}` : undefined}
              avatarUrl={profile.avatar_url || undefined}
              location={profile.location || undefined}
              bio={profile.bio || undefined}
            />
          ))}
        </div>
      </div>
    </div>
  );
};