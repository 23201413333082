import React from 'react';
import { Mail, MapPin, Globe, Linkedin, Twitter, Github } from 'lucide-react';
import { Link } from 'react-router-dom';
import { EventCounter } from './EventCounter';

interface ProfileCardProps {
  fullName: string;
  title?: string;
  avatarUrl?: string;
  email?: string;
  location?: string;
  bio?: string;
  skills?: string[];
  userId?: string;
  socialLinks?: {
    linkedin?: string;
    twitter?: string;
    github?: string;
    website?: string;
  };
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
  fullName,
  title,
  avatarUrl,
  email,
  location,
  bio,
  skills = [],
  userId,
  socialLinks = {}
}) => {
  return (
    <div className="w-full max-w-md bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="p-6">
        <div className="flex items-center space-x-4">
          <div className="relative">
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={fullName}
                className="w-20 h-20 rounded-full object-cover border-2 border-gray-100"
              />
            ) : (
              <div className="w-20 h-20 rounded-full bg-gradient-to-br from-accent-blue to-accent-red flex items-center justify-center text-white text-2xl font-bold">
                {fullName?.charAt(0) || '?'}
              </div>
            )}
          </div>
          
          <div className="flex-1">
            <h2 className="text-xl font-bold text-gray-900">{fullName || 'Anonymous User'}</h2>
            {title && (
              <p className="text-sm text-accent-blue">{title}</p>
            )}
            {userId && (
              <EventCounter userId={userId} className="mt-2" />
            )}
          </div>
        </div>

        <div className="mt-6 space-y-4">
          {bio && (
            <p className="text-gray-600 text-sm">{bio}</p>
          )}

          <div className="space-y-2">
            {email && (
              <div className="flex items-center text-sm text-gray-600">
                <Mail className="w-4 h-4 mr-2 text-accent-red" />
                <span>{email}</span>
              </div>
            )}
            
            {location && (
              <div className="flex items-center text-sm text-gray-600">
                <MapPin className="w-4 h-4 mr-2 text-accent-red" />
                <span>{location}</span>
              </div>
            )}
          </div>

          {skills.length > 0 && (
            <div className="pt-4">
              <h3 className="text-sm font-medium text-gray-900 mb-2">Skills</h3>
              <div className="flex flex-wrap gap-2">
                {skills.slice(0, 5).map((skill, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 text-xs font-medium bg-gray-100 text-gray-700 rounded-full"
                  >
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          )}

          {Object.keys(socialLinks).length > 0 && (
            <div className="pt-4 flex items-center space-x-4">
              {socialLinks.linkedin && (
                <a
                  href={socialLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-accent-blue transition-colors"
                >
                  <Linkedin className="w-5 h-5" />
                </a>
              )}
              {socialLinks.twitter && (
                <a
                  href={socialLinks.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-accent-blue transition-colors"
                >
                  <Twitter className="w-5 h-5" />
                </a>
              )}
              {socialLinks.github && (
                <a
                  href={socialLinks.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900 transition-colors"
                >
                  <Github className="w-5 h-5" />
                </a>
              )}
              {socialLinks.website && (
                <a
                  href={socialLinks.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-accent-red transition-colors"
                >
                  <Globe className="w-5 h-5" />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};