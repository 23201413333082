import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Users, Globe, Shield } from 'lucide-react';

export const About: React.FC = () => {
  return (
    <div className="min-h-screen bg-primary-light dark:bg-dark-bg-primary py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/" className="inline-flex items-center text-accent-red hover:text-red-700 mb-8">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-white dark:bg-dark-surface-primary rounded-2xl shadow-xl p-8">
          <div className="flex items-center justify-center mb-8">
            <Calendar className="h-12 w-12 text-accent-red mr-4" />
            <h1 className="text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">About Events</h1>
          </div>

          <div className="prose dark:prose-invert max-w-none">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div className="flex flex-col items-center text-center p-6 rounded-xl bg-gray-50 dark:bg-dark-surface-secondary">
                <Users className="w-12 h-12 text-accent-red mb-4" />
                <h3 className="text-xl font-semibold mb-2">Community First</h3>
                <p className="text-gray-600 dark:text-gray-300">
                  Building connections and fostering meaningful relationships within our diverse community.
                </p>
              </div>
              
              <div className="flex flex-col items-center text-center p-6 rounded-xl bg-gray-50 dark:bg-dark-surface-secondary">
                <Globe className="w-12 h-12 text-accent-red mb-4" />
                <h3 className="text-xl font-semibold mb-2">Global Reach</h3>
                <p className="text-gray-600 dark:text-gray-300">
                  Connecting event organizers and attendees across borders and cultures.
                </p>
              </div>
            </div>

            <h2>Our Story</h2>
            <p>
              Founded in 2024, Events emerged from a simple idea: making it easier for people
              to discover and create meaningful experiences in their communities. What started
              as a small project has grown into a vibrant platform connecting thousands of
              event organizers with passionate attendees.
            </p>

            <h2>Our Mission</h2>
            <p>
              We believe in the power of shared experiences to bring people together and
              create lasting connections. Our mission is to make event discovery and
              organization accessible to everyone, fostering community engagement and
              cultural exchange.
            </p>

            <div className="bg-accent-red/5 border border-accent-red/10 rounded-xl p-6 my-8">
              <div className="flex items-start">
                <Shield className="w-6 h-6 text-accent-red flex-shrink-0 mt-1" />
                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-accent-red">Our Commitment</h3>
                  <p className="mt-2">
                    We're committed to providing a secure, inclusive platform where everyone
                    feels welcome to share and discover events. Our team works tirelessly
                    to maintain high standards of quality and safety for all users.
                  </p>
                </div>
              </div>
            </div>

            <h2>Join Our Community</h2>
            <p>
              Whether you're an event organizer or an attendee, we invite you to be part
              of our growing community. Together, we can create and discover experiences
              that enrich our lives and strengthen our connections.
            </p>

            <div className="mt-8 flex justify-center">
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 bg-accent-red text-white rounded-lg hover:bg-red-700 transition-colors"
              >
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};