import React from 'react';
import { Calendar } from 'lucide-react';
import { useEventCount } from '../../hooks/useEventCount';

interface EventCounterProps {
  userId: string;
  className?: string;
}

export const EventCounter: React.FC<EventCounterProps> = ({ userId, className = '' }) => {
  const eventCount = useEventCount(userId);

  return (
    <div 
      className={`flex items-center gap-2 ${className}`}
      aria-label={`${eventCount} events created by this user`}
    >
      <Calendar className="w-4 h-4 text-accent-red" />
      <div className="flex items-baseline gap-1">
        <span className="font-medium">My Events:</span>
        <span className="text-accent-red font-semibold">{eventCount}</span>
      </div>
    </div>
  );
};