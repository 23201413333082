import { supabase } from '../supabase';

export async function promoteToAdmin(userId: string) {
  try {
    // First verify the current user is an admin
    const { data: { user } } = await supabase.auth.getUser();
    if (!user?.user_metadata?.role === 'admin') {
      throw new Error('Unauthorized: Only admins can promote users');
    }

    // Update user metadata through Supabase's auth API
    const { error } = await supabase.auth.admin.updateUserById(userId, {
      user_metadata: { role: 'admin' }
    });

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error promoting user to admin:', error);
    throw error;
  }
}

export async function deleteAccount() {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    // Delete user data first
    const { error: dataError } = await supabase
      .from('events')
      .delete()
      .eq('user_id', user.id);

    if (dataError) throw dataError;

    // Sign out after successful deletion
    await supabase.auth.signOut();

    return true;
  } catch (error) {
    console.error('Error deleting account:', error);
    throw error;
  }
}

export async function checkAdminStatus(userId: string): Promise<boolean> {
  try {
    const { data: { user }, error } = await supabase.auth.getUser();
    
    if (error) throw error;
    if (!user) return false;
    
    return !!user.user_metadata?.is_admin;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}

export async function refreshUserSession() {
  try {
    const { data: { session }, error } = await supabase.auth.refreshSession();
    if (error) throw error;
    return session;
  } catch (error) {
    console.error('Error refreshing session:', error);
    throw error;
  }
}