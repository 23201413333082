import React, { useEffect, useState } from 'react';
import { Check, X, Shield, AlertCircle } from 'lucide-react';

interface PasswordRequirement {
  label: string;
  regex: RegExp;
  met: boolean;
  description?: string;
}

interface PasswordStrengthIndicatorProps {
  password: string;
  onStrengthChange?: (isStrong: boolean) => void;
}

const strengthLabels = {
  weak: { 
    label: 'Weak', 
    color: 'text-red-600',
    message: 'This password is too weak and easily guessable'
  },
  medium: { 
    label: 'Medium', 
    color: 'text-yellow-600',
    message: 'Getting better, but could be stronger'
  },
  strong: { 
    label: 'Strong', 
    color: 'text-green-600',
    message: 'Great! This password is strong and secure'
  }
};

export const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({
  password,
  onStrengthChange
}) => {
  const [requirements, setRequirements] = useState<PasswordRequirement[]>([
    { 
      label: 'At least 8 characters', 
      regex: /.{8,}/, 
      met: false,
      description: 'Make your password at least 8 characters long'
    },
    { 
      label: 'Uppercase letter', 
      regex: /[A-Z]/, 
      met: false,
      description: 'Include at least one uppercase letter (A-Z)'
    },
    { 
      label: 'Lowercase letter', 
      regex: /[a-z]/, 
      met: false,
      description: 'Include at least one lowercase letter (a-z)'
    },
    { 
      label: 'Number', 
      regex: /[0-9]/, 
      met: false,
      description: 'Include at least one number (0-9)'
    },
    { 
      label: 'Special character', 
      regex: /[!@#$%^&*()_+\-=[\]{};:,.<>?]/, 
      met: false,
      description: 'Include at least one special character (!@#$%^&*)'
    }
  ]);

  const [strength, setStrength] = useState<'weak' | 'medium' | 'strong'>('weak');
  const [hoveredRequirement, setHoveredRequirement] = useState<number | null>(null);

  useEffect(() => {
    const updatedRequirements = requirements.map(req => ({
      ...req,
      met: req.regex.test(password)
    }));
    setRequirements(updatedRequirements);

    const metCount = updatedRequirements.filter(req => req.met).length;
    let newStrength: 'weak' | 'medium' | 'strong' = 'weak';

    if (metCount === requirements.length) {
      newStrength = 'strong';
    } else if (metCount >= 3) {
      newStrength = 'medium';
    }

    setStrength(newStrength);
    onStrengthChange?.(newStrength === 'strong');
  }, [password, onStrengthChange]);

  const getStrengthColor = () => {
    switch (strength) {
      case 'strong':
        return 'bg-green-500';
      case 'medium':
        return 'bg-yellow-500';
      default:
        return 'bg-red-500';
    }
  };

  const getStrengthWidth = () => {
    switch (strength) {
      case 'strong':
        return 'w-full';
      case 'medium':
        return 'w-2/3';
      default:
        return 'w-1/3';
    }
  };

  return (
    <div className="space-y-4 bg-gray-50 dark:bg-dark-surface-secondary rounded-lg p-4 shadow-sm border border-gray-200" role="alert" aria-live="polite">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Shield className={`w-5 h-5 ${strengthLabels[strength].color}`} />
          <span className={`font-medium ${strengthLabels[strength].color}`}>
            Password Strength: {strengthLabels[strength].label}
          </span>
        </div>
        <div className="flex items-center">
          <AlertCircle className={`w-4 h-4 mr-2 ${strengthLabels[strength].color}`} />
          <span className={`text-sm ${strengthLabels[strength].color}`}>
            {strengthLabels[strength].message}
          </span>
        </div>
      </div>

      <div className="h-2 w-full bg-gray-200 rounded-full overflow-hidden">
        <div
          className={`h-full ${getStrengthColor()} ${getStrengthWidth()} transition-all duration-300`}
          role="progressbar"
          aria-valuenow={strength === 'strong' ? 100 : strength === 'medium' ? 66 : 33}
          aria-valuemin={0}
          aria-valuemax={100}
          aria-label={`Password strength: ${strengthLabels[strength].label}`}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        {requirements.map((req, index) => (
          <div
            key={index}
            className={`flex items-center text-sm transition-colors duration-200 ${
              req.met ? 'text-green-600' : 'text-gray-500'
            }`}
            role="status"
            aria-label={`${req.label}: ${req.met ? 'met' : 'not met'}`}
            onMouseEnter={() => setHoveredRequirement(index)}
            onMouseLeave={() => setHoveredRequirement(null)}
          >
            {req.met ? (
              <Check className="w-4 h-4 mr-2 flex-shrink-0 animate-scale-check" />
            ) : (
              <X className="w-4 h-4 mr-2 flex-shrink-0" />
            )}
            <span>{req.label}</span>
            {hoveredRequirement === index && (
              <div className="absolute left-0 -top-8 w-48 bg-gray-900 text-white text-xs rounded py-1 px-2 z-10">
                {req.description}
                <div className="absolute bottom-0 left-4 transform translate-y-1/2 rotate-45 w-2 h-2 bg-gray-900" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};