import React from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isSameDay } from 'date-fns';
import { Calendar as CalendarIcon, Clock, ChevronLeft, ChevronRight, MapPin } from 'lucide-react';
import { useEvents } from '../../hooks/useEvents';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

export const DashboardCalendar: React.FC = () => {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const formattedTime = format(new Date(), 'h:mm a');
  const { events } = useEvents();

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const previousMonth = () => {
    setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const nextMonth = () => {
    setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const getDayEvents = (date: Date) => {
    return events.filter(event => isSameDay(new Date(event.date), date));
  };

  return (
    <div className="bg-white dark:bg-dark-surface-primary rounded-xl shadow-lg p-6 mb-6">
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center space-x-4">
          <div className="p-2 bg-accent-red/10 rounded-lg">
            <CalendarIcon className="w-5 h-5 text-accent-red" />
          </div>
          <div>
            <h2 className="text-lg font-bold text-gray-900 dark:text-white">
              {format(currentDate, 'MMMM yyyy')}
            </h2>
            <div className="flex items-center mt-1 text-gray-500 dark:text-gray-400">
              <Clock className="w-3.5 h-3.5 mr-1" />
              <span className="text-sm">{formattedTime}</span>
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={previousMonth}
            className="p-1.5 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <button
            onClick={nextMonth}
            className="p-1.5 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1 mb-1">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div
            key={day}
            className="text-center text-sm font-medium text-gray-500 dark:text-gray-400 py-2"
          >
            {day.charAt(0)}
          </div>
        ))}
        
        {daysInMonth.map((date, index) => {
          const dayEvents = getDayEvents(date);
          const isCurrentMonth = isSameMonth(date, currentDate);
          const isSelected = isSameDay(date, selectedDate);
          const isCurrentDay = isToday(date);

          return (
            <button
              key={date.toString()}
              onClick={() => setSelectedDate(date)}
              className={`relative p-3 text-sm font-medium rounded-lg transition-all duration-200
                ${!isCurrentMonth && 'text-gray-400 dark:text-gray-600'}
                ${isSelected && 'bg-accent-red text-white shadow-lg transform scale-105'}
                ${isCurrentDay && !isSelected && 'bg-accent-red/10 text-accent-red ring-2 ring-accent-red/20'}
                ${isCurrentMonth && !isSelected && !isCurrentDay && 'hover:bg-gray-100 dark:hover:bg-dark-surface-secondary'}
              `}
            >
              <span className="relative z-10">{format(date, 'd')}</span>
              {dayEvents.length > 0 && (
                <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 flex items-center justify-center space-x-0.5">
                  {dayEvents.map((_, idx) => (
                    <span
                      key={idx}
                      className={`w-1.5 h-1.5 rounded-full ${
                        isSelected ? 'bg-white/90' : 'bg-accent-red'
                      } ${isSelected ? 'shadow-white' : 'shadow-sm'}`}
                      style={{
                        transform: `translateX(${idx * 4}px)`,
                      }}
                    />
                  ))}
                </div>
              )}
            </button>
          );
        })}
      </div>

      {/* Selected Date Events */}
      {selectedDate && (
        <div className="mt-6 border-t border-gray-200 dark:border-dark-border-primary pt-4">
          <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-3">
            Events for {format(selectedDate, 'MMMM d, yyyy')}
          </h3>
          <div className="space-y-2">
            {getDayEvents(selectedDate).length > 0 ? (
              getDayEvents(selectedDate).map(event => (
                <Link
                  to={`/event/${event.id}`}
                  key={event.id}
                  className="block p-3 bg-gray-50 hover:bg-gray-100 dark:bg-dark-surface-secondary dark:hover:bg-dark-surface-hover rounded-lg transition-all duration-200 hover:shadow-md group relative overflow-hidden border border-gray-200/50"
                >
                  <div className="absolute left-0 top-0 bottom-0 w-1 bg-accent-red" />
                  <p className="font-medium text-sm text-gray-900 dark:text-white mb-1 group-hover:text-accent-red transition-colors">
                    {event.title}
                  </p>
                  <div className="flex items-center text-xs text-gray-500 dark:text-gray-400 mb-1">
                    {format(new Date(event.date), 'h:mm a')}
                    <span className="mx-2">•</span>
                    <span>By {event.organizer}</span>
                  </div>
                  <div className="flex items-start text-xs text-gray-500 dark:text-gray-400">
                    <MapPin className="w-3.5 h-3.5 mt-0.5 mr-1.5 flex-shrink-0 text-accent-red" />
                    <div>
                      <p>{event.addressStreet},</p>
                      <p>{event.addressCity}, {event.addressState} {event.addressZip}</p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="text-sm text-gray-500 dark:text-gray-400 text-center py-4">
                No events scheduled for this day
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};