import React, { useState } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isSameDay } from 'date-fns';
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight, List, Grid } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { Event } from '../../types/event';

interface EventCalendarProps {
  events: Event[];
}

export const EventCalendar: React.FC<EventCalendarProps> = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewMode, setViewMode] = useState<'calendar' | 'list'>('calendar');

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const previousMonth = () => {
    setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const nextMonth = () => {
    setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const getDayEvents = (date: Date) => {
    return events.filter(event => isSameDay(new Date(event.date), date));
  };

  const upcomingEvents = events
    .filter(event => new Date(event.date) >= new Date())
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .slice(0, 5);

  return (
    <div className="bg-white dark:bg-dark-surface-primary rounded-xl shadow-lg p-3 sm:p-6 min-h-[400px]">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-4">
          <div className="p-2 bg-accent-red/10 rounded-lg">
            <CalendarIcon className="w-6 h-6 text-accent-red" />
          </div>
          <h2 className="text-lg sm:text-xl font-bold text-gray-900 dark:text-white">
            {format(currentDate, 'MMMM yyyy')}
          </h2>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setViewMode(viewMode === 'calendar' ? 'list' : 'calendar')}
            className="p-2 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
            title={viewMode === 'calendar' ? 'Switch to list view' : 'Switch to calendar view'}
          >
            {viewMode === 'calendar' ? (
              <List className="w-5 h-5" />
            ) : (
              <Grid className="w-5 h-5" />
            )}
          </button>
          <button
            onClick={previousMonth}
            className="p-2 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={nextMonth}
            className="p-2 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      {viewMode === 'calendar' ? (
        <div className="grid grid-cols-7 gap-0.5 sm:gap-1">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div
              key={day}
              className="text-center text-xs sm:text-sm font-medium text-gray-500 dark:text-gray-400 py-1 sm:py-2"
            >
              {window.innerWidth < 640 ? day.charAt(0) : day}
            </div>
          ))}
          
          {daysInMonth.map((date) => {
            const dayEvents = getDayEvents(date);
            const isCurrentMonth = isSameMonth(date, currentDate);
            const isSelected = isSameDay(date, selectedDate);
            const isCurrentDay = isToday(date);

            return (
              <button
                key={date.toString()}
                onClick={() => setSelectedDate(date)}
                className={`relative p-1.5 sm:p-3 text-xs sm:text-sm font-medium rounded-lg transition-all duration-200
                  ${!isCurrentMonth && 'text-gray-400 dark:text-gray-600'}
                  ${isSelected && 'bg-accent-red text-white shadow-lg transform scale-105'}
                  ${isCurrentDay && !isSelected && 'bg-accent-red/10 text-accent-red'}
                  ${isCurrentMonth && !isSelected && !isCurrentDay && 'hover:bg-gray-100 dark:hover:bg-dark-surface-secondary'}
                `}
              >
                <span className="relative z-10">{format(date, 'd')}</span>
                {dayEvents.length > 0 && (
                  <div className="absolute bottom-1 left-1/2 transform -translate-x-1/2 flex -space-x-1">
                    {dayEvents.map((_, idx) => (
                      <div
                        key={idx}
                        className={`w-1.5 h-1.5 rounded-full ${
                          isSelected ? 'bg-white' : 'bg-accent-red'
                        }`}
                      />
                    ))}
                  </div>
                )}
              </button>
            );
          })}
        </div>
      ) : (
        <div className="space-y-4">
          {upcomingEvents.map(event => (
            <Link
              key={event.id}
              to={`/event/${event.id}`}
              className="block p-4 hover:bg-gray-50 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">{event.title}</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                    {format(new Date(event.date), 'PPP p')}
                  </p>
                </div>
                <span className="px-2 py-1 text-xs font-medium bg-accent-red/10 text-accent-red rounded-full">
                  {event.category}
                </span>
              </div>
            </Link>
          ))}
        </div>
      )}

      {/* Selected Date Events */}
      {viewMode === 'calendar' && selectedDate && getDayEvents(selectedDate).length > 0 && (
        <div className="mt-6 border-t border-gray-200 dark:border-dark-border-primary pt-4">
          <h3 className="text-sm font-semibold text-gray-900 dark:text-white mb-3">
            Events for {format(selectedDate, 'MMMM d, yyyy')}
          </h3>
          <div className="space-y-2">
            {getDayEvents(selectedDate).map(event => (
              <Link
                key={event.id}
                to={`/event/${event.id}`}
                className="block p-3 hover:bg-gray-50 dark:hover:bg-dark-surface-secondary rounded-lg transition-colors"
              >
                <p className="font-medium text-gray-900 dark:text-white">{event.title}</p>
                <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
                  {format(new Date(event.date), 'p')} • {event.location}
                </p>
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};