import React from 'react';
import { KPICard } from './KPICard';

interface KPIData {
  title: string;
  value: string | number;
  change: number;
  icon: React.ReactNode;
}

interface KPIGridProps {
  data: KPIData[];
}

export const KPIGrid: React.FC<KPIGridProps> = ({ data }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {data.map((item, index) => (
        <KPICard
          key={index}
          title={item.title}
          value={item.value}
          change={item.change}
          icon={item.icon}
        />
      ))}
    </div>
  );
};