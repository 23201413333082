import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Calendar, MapPin, User, Tag, ArrowLeft } from 'lucide-react';
import { useEvents } from '../hooks/useEvents';

export const EventDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { events } = useEvents();
  const event = events.find(e => e.id === id);

  if (!event) {
    return (
      <div className="min-h-screen bg-primary-light flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">Event not found</h2>
          <p className="mt-2 text-gray-600">The event you're looking for doesn't exist.</p>
          <Link
            to="/"
            className="mt-6 inline-flex items-center text-accent-red hover:text-red-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Events
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-primary-light py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link
          to="/"
          className="inline-flex items-center text-accent-red hover:text-red-700 mb-8"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Events
        </Link>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          {event.imageUrl ? (
            <img
              src={event.imageUrl}
              alt={event.title}
              className="w-full h-96 object-cover"
            />
          ) : (
            <div className="w-full h-96 bg-gradient-to-r from-accent-blue to-accent-red flex items-center justify-center">
              <Calendar className="w-24 h-24 text-white" />
            </div>
          )}
          
          <div className="p-8">
            <div className="flex items-center mb-4">
              <Tag className="w-5 h-5 mr-2 text-accent-red" />
              <span className="text-lg font-medium text-accent-red">{event.category}</span>
            </div>

            <h1 className="text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent mb-6">{event.title}</h1>
            
            <div className="space-y-6">
              <p className="text-xl text-gray-600">{event.description}</p>
              
              <div className="space-y-4">
                <div className="flex items-center text-gray-700">
                  <Calendar className="w-6 h-6 mr-3 text-accent-red" />
                  <span className="text-lg">{format(event.date, 'PPP p')}</span>
                </div>
                
                <div className="flex items-center text-gray-700">
                  <MapPin className="w-6 h-6 mr-3 text-accent-red" />
                  <span className="text-lg">{event.location}</span>
                </div>
                
                <div className="flex items-center text-gray-700">
                  <User className="w-6 h-6 mr-3 text-accent-red" />
                  <span className="text-lg">Organized by {event.organizer}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};