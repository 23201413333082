import React from 'react';
import { EventForm } from '../EventForm';
import type { Event } from '../../types/event';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../context/ToastContext';

interface EventFormSectionProps {
  onClose: () => void;
  onSubmit: (eventData: Omit<Event, 'id' | 'userId'>) => Promise<void>;
}

export const EventFormSection: React.FC<EventFormSectionProps> = ({ onClose, onSubmit }) => {
  return (
    <div className="bg-white dark:bg-dark-surface-primary shadow-xl rounded-2xl p-6 sm:p-8">
      <div className="flex items-center justify-between mb-4 sm:mb-6">
        <h2 className="text-2xl sm:text-3xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">
          Post New Event
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors"
        >
          Cancel
        </button>
      </div>
      <EventForm onSubmit={onSubmit} />
    </div>
  );
};