import React from 'react';
import { Bell, Search, Settings, User } from 'lucide-react';

export const Header: React.FC = () => {
  return (
    <header className="fixed top-0 left-0 right-0 h-16 bg-white dark:bg-dark-surface-primary border-b border-gray-200 dark:border-dark-border-primary z-50">
      <div className="h-full flex items-center justify-between px-6">
        <div className="flex items-center space-x-4">
          <h1 className="text-xl font-bold text-accent-red">Admin Portal</h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="w-64 pl-10 pr-4 py-2 bg-gray-50 dark:bg-dark-surface-secondary border border-gray-200 dark:border-dark-border-primary rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-red"
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <button className="p-2 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg relative">
            <Bell className="w-5 h-5 text-gray-600 dark:text-gray-300" />
            <span className="absolute top-1 right-1 w-2 h-2 bg-accent-red rounded-full" />
          </button>
          <button className="p-2 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg">
            <Settings className="w-5 h-5 text-gray-600 dark:text-gray-300" />
          </button>
          <button className="flex items-center space-x-2 p-2 hover:bg-gray-100 dark:hover:bg-dark-surface-secondary rounded-lg">
            <div className="w-8 h-8 bg-accent-red rounded-full flex items-center justify-center">
              <User className="w-5 h-5 text-white" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-200">Admin</span>
          </button>
        </div>
      </div>
    </header>
  );
};