import React from 'react';
import { EventCard } from './EventCard';
import type { Event } from '../types/event';

interface EventListProps {
  events: Event[];
  onEventUpdate: (updatedEvent: Event) => void;
}

export const EventList: React.FC<EventListProps> = ({ 
  events, 
  onEventUpdate,
}) => {
  return (
    <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3">
      {events.map(event => (
        <div 
          key={event.id}
          className="w-full h-full"
        >
          <EventCard 
            event={event}
            onEventUpdate={onEventUpdate}
          />
        </div>
      ))}
    </div>
  );
};