import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Mail, MessageSquare, Send, Loader2 } from 'lucide-react';
import { useToast } from '../context/ToastContext';
import { submitContactForm } from '../lib/api/contact';

export const Contact: React.FC = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await submitContactForm(formData);
      showToast('Message sent successfully! We\'ll get back to you soon.');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (error) {
      showToast('Failed to send message. Please try again later.');
      console.error('Contact form error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="min-h-screen bg-primary-light dark:bg-dark-bg-primary py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/" className="inline-flex items-center text-accent-red hover:text-red-700 mb-8">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-white dark:bg-dark-surface-primary rounded-2xl shadow-xl p-8">
          <h1 className="text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent mb-6">Contact Us</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-xl font-semibold mb-4 dark:text-dark-text-primary">Get in Touch</h2>
              <p className="text-gray-600 dark:text-dark-text-secondary mb-6">
                Have questions or feedback? We'd love to hear from you. Send us a message
                and we'll respond as soon as possible.
              </p>
              
              <div className="space-y-4">
                <div className="flex items-center text-gray-600 dark:text-dark-text-secondary">
                  <Mail className="w-5 h-5 mr-3 text-accent-red" />
                  <span>support@events.com</span>
                </div>
                <div className="flex items-center text-gray-600 dark:text-dark-text-secondary">
                  <MessageSquare className="w-5 h-5 mr-3 text-accent-red" />
                  <span>Live chat available 9am-5pm EST</span>
                </div>
              </div>

              <div className="mt-8 p-4 bg-gray-50 dark:bg-dark-surface-secondary rounded-lg">
                <h3 className="font-medium text-gray-900 dark:text-dark-text-primary mb-2">
                  Office Hours
                </h3>
                <ul className="space-y-2 text-sm text-gray-600 dark:text-dark-text-secondary">
                  <li>Monday - Friday: 9:00 AM - 6:00 PM EST</li>
                  <li>Saturday: 10:00 AM - 4:00 PM EST</li>
                  <li>Sunday: Closed</li>
                </ul>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-dark-text-secondary mb-1">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  disabled={loading}
                  className="w-full px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-dark-border-primary dark:bg-dark-surface-primary dark:text-dark-text-primary focus:ring-2 focus:ring-accent-red focus:border-accent-red disabled:opacity-50"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-dark-text-secondary mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={loading}
                  className="w-full px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-dark-border-primary dark:bg-dark-surface-primary dark:text-dark-text-primary focus:ring-2 focus:ring-accent-red focus:border-accent-red disabled:opacity-50"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-dark-text-secondary mb-1">
                  Subject
                </label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  disabled={loading}
                  className="w-full px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-dark-border-primary dark:bg-dark-surface-primary dark:text-dark-text-primary focus:ring-2 focus:ring-accent-red focus:border-accent-red disabled:opacity-50"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-dark-text-secondary mb-1">
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  disabled={loading}
                  rows={4}
                  className="w-full px-4 py-2 rounded-lg border-2 border-gray-200 dark:border-dark-border-primary dark:bg-dark-surface-primary dark:text-dark-text-primary focus:ring-2 focus:ring-accent-red focus:border-accent-red disabled:opacity-50"
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className="w-full bg-accent-red hover:bg-red-700 text-white font-medium py-2 px-4 rounded-lg inline-flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                ) : (
                  <Send className="w-4 h-4 mr-2" />
                )}
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};