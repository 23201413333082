import React, { useState, useEffect } from 'react';
import { Calendar, MapPin, User, Tag } from 'lucide-react';
import type { Event } from '../types/event';
import { states } from '../data/states';
import { CategoryChips } from './CategoryChips';
import { ExpandableDescription } from './ExpandableDescription';
import { useAuth } from '../context/AuthContext';
import { ImageDropzone } from './ImageDropzone';
import { Confetti } from './Confetti';
import { format } from 'date-fns';

interface EventFormProps {
  onSubmit: (event: Omit<Event, 'id' | 'userId'>) => void;
  initialData?: Event;
}

export const EventForm: React.FC<EventFormProps> = ({ onSubmit, initialData }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    location: '',
    addressStreet: '',
    addressCity: '',
    addressState: '',
    addressZip: '',
    organizer: user?.user_metadata?.full_name || '',
    category: '',
    imageUrl: initialData?.imageUrl || ''
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFormData({
        title: initialData.title,
        description: initialData.description,
        date: format(initialData.date, "yyyy-MM-dd'T'HH:mm"),
        location: initialData.location,
        addressStreet: initialData.addressStreet || '',
        addressCity: initialData.addressCity || '',
        addressState: initialData.addressState || '',
        addressZip: initialData.addressZip || '',
        organizer: initialData.organizer,
        category: initialData.category,
        imageUrl: initialData.imageUrl || '',
        backgroundColor: initialData.backgroundColor || '#ffffff'
      });
    }
  }, [initialData]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Title is required';
    } else if (formData.title.length < 3) {
      newErrors.title = 'Title must be at least 3 characters';
    }

    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }

    if (!formData.date) {
      newErrors.date = 'Date is required';
    } else {
      const selectedDate = new Date(formData.date);
      if (selectedDate <= new Date()) {
        newErrors.date = 'Date must be in the future';
      }
    }

    if (!formData.addressStreet.trim()) {
      newErrors.addressStreet = 'Street address is required';
    }

    if (!formData.addressCity.trim()) {
      newErrors.addressCity = 'City is required';
    }

    if (!formData.addressState) {
      newErrors.addressState = 'State is required';
    }

    if (!formData.addressZip.trim()) {
      newErrors.addressZip = 'ZIP code is required';
    } else if (!/^\d{5}(-\d{4})?$/.test(formData.addressZip)) {
      newErrors.addressZip = 'Invalid ZIP code format';
    }

    if (!formData.organizer.trim()) {
      newErrors.organizer = 'Organizer is required';
    }

    if (!formData.category) {
      newErrors.category = 'Category is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    onSubmit({
      ...formData,
      date: new Date(formData.date),
      location: `${formData.addressCity}, ${formData.addressState}`,
      addressStreet: formData.addressStreet,
      addressCity: formData.addressCity,
      addressState: formData.addressState,
      addressZip: formData.addressZip,
      imageUrl: formData.imageUrl
    });
    
    // Trigger confetti after successful submission
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 2000);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleDescriptionChange = (value: string) => {
    setFormData(prev => ({ ...prev, description: value }));
    if (errors.description) {
      setErrors(prev => ({ ...prev, description: '' }));
    }
  };

  const handleCategoryChange = (category: string) => {
    setFormData(prev => ({ ...prev, category }));
    if (errors.category) {
      setErrors(prev => ({ ...prev, category: '' }));
    }
  };

  const handleImageUpload = (url: string) => {
    setFormData(prev => ({ ...prev, imageUrl: url }));
  };

  const handleRemoveImage = () => {
    setFormData(prev => ({ ...prev, imageUrl: '' }));
  };

  const inputClasses = "form-input mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2.5 text-gray-700 focus:border-accent-red focus:ring focus:ring-accent-red/20";
  const labelClasses = "flex items-center text-sm font-medium text-gray-700";
  const errorClasses = "text-sm text-red-600 mt-1";

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <Confetti trigger={showConfetti} />
      <ImageDropzone
        onImageUpload={handleImageUpload}
        existingImage={formData.imageUrl}
        onRemoveImage={handleRemoveImage}
      />

      <div>
        <label htmlFor="title" className={labelClasses}>
          <Tag className="w-4 h-4 mr-1.5" />
          Event Title
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className={`${inputClasses} ${errors.title ? 'border-red-500' : ''}`}
          placeholder="e.g., Community Art Exhibition"
        />
        {errors.title && <p className={errorClasses}>{errors.title}</p>}
      </div>

      <CategoryChips
        selectedCategory={formData.category}
        onChange={handleCategoryChange}
      />
      {errors.category && <p className={errorClasses}>{errors.category}</p>}

      <div className="space-y-4">
        <label className={labelClasses}>
          <MapPin className="w-4 h-4 mr-1.5" />
          Event Location
        </label>

        <div>
          <label htmlFor="addressStreet" className="block text-sm font-medium text-gray-700">
            Street Address
          </label>
          <input
            type="text"
            id="addressStreet"
            name="addressStreet"
            value={formData.addressStreet}
            onChange={handleChange}
            className={`${inputClasses} ${errors.addressStreet ? 'border-red-500' : ''}`}
            placeholder="123 Main St"
          />
          {errors.addressStreet && <p className={errorClasses}>{errors.addressStreet}</p>}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="col-span-1">
            <label htmlFor="addressCity" className="block text-sm font-medium text-gray-700">
              City
            </label>
            <input
              type="text"
              id="addressCity"
              name="addressCity"
              value={formData.addressCity}
              onChange={handleChange}
              className={`${inputClasses} ${errors.addressCity ? 'border-red-500' : ''}`}
              placeholder="City"
            />
            {errors.addressCity && <p className={errorClasses}>{errors.addressCity}</p>}
          </div>

          <div className="col-span-1">
            <label htmlFor="addressState" className="block text-sm font-medium text-gray-700">
              State
            </label>
            <select
              id="addressState"
              name="addressState"
              value={formData.addressState}
              onChange={handleChange}
              className={`${inputClasses} ${errors.addressState ? 'border-red-500' : ''}`}
            >
              <option value="">Select a state</option>
              {states.map(state => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {errors.addressState && <p className={errorClasses}>{errors.addressState}</p>}
          </div>

          <div className="col-span-1">
            <label htmlFor="addressZip" className="block text-sm font-medium text-gray-700">
              ZIP Code
            </label>
            <input
              type="text"
              id="addressZip"
              name="addressZip"
              value={formData.addressZip}
              onChange={handleChange}
              className={`${inputClasses} ${errors.addressZip ? 'border-red-500' : ''}`}
              placeholder="12345"
              maxLength={10}
            />
            {errors.addressZip && <p className={errorClasses}>{errors.addressZip}</p>}
          </div>
        </div>
      </div>

      <div>
        <label htmlFor="description" className={labelClasses}>
          Description
        </label>
        <ExpandableDescription
          text={formData.description}
          maxLength={100}
          onChange={handleDescriptionChange}
          editable={true}
        />
        {errors.description && <p className={errorClasses}>{errors.description}</p>}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="date" className={labelClasses}>
            <Calendar className="w-4 h-4 mr-1.5" />
            Event Date & Time
          </label>
          <input
            type="datetime-local"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className={`${inputClasses} ${errors.date ? 'border-red-500' : ''}`}
          />
          {errors.date && <p className={errorClasses}>{errors.date}</p>}
        </div>

        <div>
          <label htmlFor="organizer" className={labelClasses}>
            <User className="w-4 h-4 mr-1.5" />
            Organizer
          </label>
          <input
            type="text"
            id="organizer"
            name="organizer"
            value={formData.organizer}
            onChange={handleChange}
            className={`${inputClasses} ${errors.organizer ? 'border-red-500' : ''}`}
            placeholder="Your name or organization"
          />
          {errors.organizer && <p className={errorClasses}>{errors.organizer}</p>}
        </div>
      </div>

      <div>
        <label htmlFor="imageUrl" className={labelClasses}>
          Image URL (optional)
        </label>
        <input
          type="url"
          id="imageUrl"
          name="imageUrl"
          value={formData.imageUrl}
          onChange={handleChange}
          className={inputClasses}
          placeholder="https://example.com/image.jpg"
        />
      </div>

      <button
        type="submit"
        className="w-full bg-accent-red text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors duration-200 mt-8"
      >
        {initialData ? 'Update Event' : 'Post Event'}
      </button>
    </form>
  );
};