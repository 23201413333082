import React from 'react';
import { format } from 'date-fns';
import { Calendar, MapPin, User, Tag, Edit2, Trash2 } from 'lucide-react';
import type { Event } from '../../types/event';
import { ExpandableDescription } from '../ExpandableDescription';

interface DashboardEventCardProps {
  event: Event;
  onEdit: () => void;
  onDelete: () => void;
}

export const DashboardEventCard: React.FC<DashboardEventCardProps> = ({
  event,
  onEdit,
  onDelete
}) => {
  return (
    <div className="group transform-gpu rounded-xl overflow-hidden h-full relative transition-all duration-300">
      <div className="absolute inset-0 rounded-xl bg-gradient-to-b from-white/10 to-white/5 dark:from-white/5 dark:to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300" />
      <div className="relative h-full backdrop-blur-[12px] bg-white/70 dark:bg-dark-surface-primary/80 
        border border-accent-blue/20 dark:border-white/20 shadow-lg 
        hover:bg-white/75 dark:hover:bg-dark-surface-primary/90 group-hover:translate-y-[-2px]
        shadow-[0_8px_32px_0_rgba(31,38,135,0.07)] hover:shadow-[0_8px_32px_0_rgba(31,38,135,0.1)]">
      <div className="relative h-48">
        {event.imageUrl ? (
          <img
            src={event.imageUrl}
            alt={event.title}
            className="w-full h-full object-cover transition-all duration-300 group-hover:scale-105 group-hover:brightness-105"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-accent-blue/80 via-purple-500/60 to-accent-red/80 backdrop-blur-sm flex items-center justify-center overflow-hidden">
            <Calendar className="w-16 h-16 text-white/90 transition-transform duration-300 group-hover:scale-110" />
          </div>
        )}
        <div className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <button
            onClick={onEdit}
            className="p-2 bg-white/80 backdrop-blur-[2px] rounded-full shadow-lg hover:bg-white transition-all duration-200 hover:scale-110 border border-white/40"
          >
            <Edit2 className="w-4 h-4 text-accent-red" />
          </button>
          <button
            onClick={onDelete}
            className="p-2 bg-white/80 backdrop-blur-[2px] rounded-full shadow-lg hover:bg-white transition-all duration-200 hover:scale-110 border border-white/40"
          >
            <Trash2 className="w-4 h-4 text-red-600" />
          </button>
        </div>
        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
          <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-white/30 backdrop-blur-[2px] text-white border border-white/40">
            <Tag className="w-3 h-3 mr-1" />
            {event.category}
          </span>
        </div>
      </div>

      <div className="p-5">
        <h3 className="text-lg font-bold text-gray-800 dark:text-white mb-3 line-clamp-2 group-hover:text-accent-red transition-colors duration-200">
          {event.title}
        </h3>
        <div className="mb-3">
          <ExpandableDescription text={event.description} maxLength={100} />
        </div>

        <div className="space-y-0.5">
          <div className="flex items-center text-gray-700 dark:text-gray-300 backdrop-blur-[2px]">
            <Calendar className="w-4 h-4 mr-2.5 text-accent-red flex-shrink-0" />
            <span className="truncate">{format(event.date, 'PPP p')}</span>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-300 backdrop-blur-[2px]">
            <MapPin className="w-4 h-4 mr-2.5 text-accent-red flex-shrink-0" />
            <span className="truncate">{event.location}</span>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-300 backdrop-blur-[2px]">
            <User className="w-4 h-4 mr-2.5 text-accent-red flex-shrink-0" />
            <span className="truncate">{event.organizer}</span>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 border-2 border-accent-red opacity-0 group-hover:opacity-100 rounded-xl transition-opacity duration-200 pointer-events-none" />
      </div>
    </div>
  );
};