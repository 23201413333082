import React, { useState } from 'react';
import { Trash2, Edit2, Save, X } from 'lucide-react';

interface StickyNoteProps {
  id: string;
  content: string;
  color: string;
  onDelete: (id: string) => void;
  onUpdate: (id: string, content: string) => void;
}

export const StickyNote: React.FC<StickyNoteProps> = ({
  id,
  content,
  color,
  onDelete,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const handleSave = () => {
    onUpdate(id, editedContent);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedContent(content);
    setIsEditing(false);
  };

  return (
    <div 
      className={`${color} p-4 rounded-lg shadow-lg transform transition-all duration-200 hover:-translate-y-1 hover:shadow-xl`}
    >
      {isEditing ? (
        <div className="space-y-2">
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="w-full h-24 p-2 text-gray-700 bg-white/90 rounded border-none focus:ring-2 focus:ring-accent-red resize-none"
            placeholder="Write your note..."
          />
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleCancel}
              className="p-1 text-gray-600 hover:text-gray-800"
              title="Cancel"
            >
              <X className="w-4 h-4" />
            </button>
            <button
              onClick={handleSave}
              className="p-1 text-green-600 hover:text-green-800"
              title="Save"
            >
              <Save className="w-4 h-4" />
            </button>
          </div>
        </div>
      ) : (
        <>
          <p className="text-gray-700 mb-4 whitespace-pre-wrap">{content}</p>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setIsEditing(true)}
              className="p-1 text-blue-600 hover:text-blue-800"
              title="Edit"
            >
              <Edit2 className="w-4 h-4" />
            </button>
            <button
              onClick={() => onDelete(id)}
              className="p-1 text-red-600 hover:text-red-800"
              title="Delete"
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        </>
      )}
    </div>
  );
};