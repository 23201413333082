import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

export const useEventCount = (userId: string): number => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchEventCount = async () => {
      try {
        const { count: eventCount, error } = await supabase
          .from('events')
          .select('*', { count: 'exact', head: true })
          .eq('user_id', userId);

        if (error) {
          console.error('Error fetching event count:', error);
          return;
        }

        setCount(eventCount || 0);
      } catch (error) {
        console.error('Error in useEventCount:', error);
      }
    };

    fetchEventCount();

    // Subscribe to changes
    const channel = supabase
      .channel('event-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'events',
          filter: `user_id=eq.${userId}`
        },
        () => {
          fetchEventCount();
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [userId]);

  return count;
};