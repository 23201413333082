import React, { useState } from 'react';
import { Plus, AlertCircle } from 'lucide-react';
import { StickyNote } from './StickyNote';
import { useToast } from '../../context/ToastContext';

interface Note {
  id: string;
  content: string;
  color: string;
}

const COLORS = [
  'bg-yellow-100',
  'bg-green-100',
  'bg-blue-100',
  'bg-pink-100',
  'bg-purple-100',
];

export const NotesGrid: React.FC = () => {
  const { showToast } = useToast();
  const [notes, setNotes] = useState<Note[]>(() => {
    const savedNotes = localStorage.getItem('dashboard-notes');
    return savedNotes ? JSON.parse(savedNotes) : [];
  });

  const saveNotes = (updatedNotes: Note[]) => {
    setNotes(updatedNotes);
    localStorage.setItem('dashboard-notes', JSON.stringify(updatedNotes));
  };

  const addNote = () => {
    if (notes.length >= 6) {
      showToast('You can only create up to 6 notes');
      return;
    }
    
    const newNote: Note = {
      id: Date.now().toString(),
      content: '',
      color: COLORS[Math.floor(Math.random() * COLORS.length)],
    };
    saveNotes([...notes, newNote]);
  };

  const updateNote = (id: string, content: string) => {
    const updatedNotes = notes.map(note =>
      note.id === id ? { ...note, content } : note
    );
    saveNotes(updatedNotes);
  };

  const deleteNote = (id: string) => {
    const updatedNotes = notes.filter(note => note.id !== id);
    saveNotes(updatedNotes);
  };

  return (
    <div className="bg-white dark:bg-dark-surface-primary rounded-xl shadow-lg p-6 mt-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-bold text-gray-900">My Notes</h2>
        <button
          onClick={addNote}
          className={`inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-lg transition-colors
            ${notes.length >= 6 
              ? 'bg-gray-300 cursor-not-allowed' 
              : 'bg-accent-red text-white hover:bg-red-700'}`}
          disabled={notes.length >= 6}
          title={notes.length >= 6 ? 'Maximum notes limit reached' : 'Add a new note'}
        >
          <Plus className="w-4 h-4 mr-1" />
          Add Note
          {notes.length >= 6 && (
            <AlertCircle className="w-4 h-4 ml-1 text-gray-600" />
          )}
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {notes.map(note => (
          <StickyNote
            key={note.id}
            id={note.id}
            content={note.content}
            color={note.color}
            onDelete={deleteNote}
            onUpdate={updateNote}
          />
        ))}
      </div>

      {notes.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          <p>No notes yet. Click "Add Note" to create one!</p>
        </div>
      )}
    </div>
  );
};