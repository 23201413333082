import React from 'react';
import { Layout } from '../../components/admin/Layout';
import { DashboardMetrics } from '../../components/admin/metrics/DashboardMetrics';
import { RecentActivity } from '../../components/admin/activity/RecentActivity';
import { SystemHealth } from '../../components/admin/monitoring/SystemHealth';

export const AdminDashboard: React.FC = () => {
  return (
    <Layout>
      <div className="p-6 space-y-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            Admin Dashboard
          </h1>
          <div className="flex items-center space-x-4">
            <span className="px-3 py-1 text-sm bg-green-100 text-green-800 rounded-full">
              System Status: Healthy
            </span>
          </div>
        </div>

        <DashboardMetrics />
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <RecentActivity />
          <SystemHealth />
        </div>
      </div>
    </Layout>
  );
};