import React from 'react';
import { Users, ShieldCheck, Database, Activity } from 'lucide-react';

const metrics = [
  {
    label: 'Total Users',
    value: '2,847',
    change: '+12.5%',
    icon: Users,
    color: 'text-blue-600',
    bg: 'bg-blue-100'
  },
  {
    label: 'Active Sessions',
    value: '486',
    change: '+5.2%',
    icon: Activity,
    color: 'text-green-600',
    bg: 'bg-green-100'
  },
  {
    label: 'Security Score',
    value: '94%',
    change: '+2.1%',
    icon: ShieldCheck,
    color: 'text-purple-600',
    bg: 'bg-purple-100'
  },
  {
    label: 'Database Size',
    value: '1.2 TB',
    change: '+8.4%',
    icon: Database,
    color: 'text-orange-600',
    bg: 'bg-orange-100'
  }
];

export const DashboardMetrics: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {metrics.map((metric) => {
        const Icon = metric.icon;
        
        return (
          <div
            key={metric.label}
            className="bg-white dark:bg-dark-surface-primary rounded-xl p-6 shadow-sm border border-gray-200 dark:border-dark-border-primary"
          >
            <div className="flex items-center justify-between">
              <div className={`p-2 ${metric.bg} rounded-lg`}>
                <Icon className={`w-6 h-6 ${metric.color}`} />
              </div>
              <span className="text-sm font-medium text-green-600">
                {metric.change}
              </span>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mt-4">
              {metric.value}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              {metric.label}
            </p>
          </div>
        );
      })}
    </div>
  );
};