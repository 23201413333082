import React from 'react';
import { ThumbsUp, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';

interface LikeButtonProps {
  liked: boolean;
  likeCount: number;
  onLike: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export const LikeButton: React.FC<LikeButtonProps> = ({
  liked,
  likeCount,
  onLike,
  disabled = false,
  isLoading = false
}) => {
  return (
    <motion.button
      onClick={onLike}
      disabled={disabled || isLoading}
      whileTap={{ scale: 0.9 }}
      className={`flex items-center gap-1 px-2 py-1 rounded-full text-xs
        transition-all duration-200 group
        ${liked
          ? 'bg-accent-blue text-white'
          : 'bg-gray-100 dark:bg-dark-surface-secondary text-gray-600 dark:text-gray-300'
        }
        ${(disabled || isLoading) ? 'opacity-50 cursor-not-allowed' : 'hover:scale-105 hover:shadow-md'}
      `}
      title={disabled ? 'Sign in to like events' : 'Like this event'}
    >
      {isLoading ? (
        <Loader2 className="w-3 h-3 animate-spin" />
      ) : (
      <motion.div
        animate={liked ? { y: [0, -4, 0] } : {}}
        transition={{ duration: 0.2 }}
      >
        <ThumbsUp
          className={`w-3 h-3 ${
            liked ? 'fill-current' : 'group-hover:text-accent-blue'
          }`}
        />
      </motion.div>
      )}
      <span className="font-medium leading-none">{likeCount}</span>
    </motion.button>
  );
};