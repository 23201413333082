import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastProvider } from './context/ToastContext';
import { AuthProvider } from './context/AuthContext';
import { Home } from './pages/Home';
import { EventDetails } from './pages/EventDetails';
import { Auth } from './components/Auth';
import { Dashboard } from './pages/Dashboard';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Analytics } from './pages/Analytics';
import { Header } from './components/header/Header';
import { Footer } from './components/layout/Footer';
import { Privacy } from './pages/Privacy';
import { Cookies } from './pages/Cookies';
import { Terms } from './pages/Terms';
import { AdminDashboard } from './pages/admin/Dashboard';
import { Profile } from './pages/Profile';
import { Contact } from './pages/Contact';
import { About } from './pages/About';
import { Profiles } from './pages/Profiles';
import { Users } from './pages/admin/Users';
import { ProtectedAdminRoute } from './components/ProtectedAdminRoute';

function App() {
  const [showForm, setShowForm] = React.useState(false);

  return (
    <Router>
      <ToastProvider>
        <AuthProvider>
          <div className="min-h-screen flex flex-col">
            <Header
              showForm={showForm}
              setShowForm={setShowForm}
            />
            <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route path="/" element={
                <Home
                  showForm={showForm}
                  setShowForm={setShowForm}
                />
              } />
              <Route path="/event/:id" element={<EventDetails />} />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard
                      showForm={showForm}
                      setShowForm={setShowForm}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/analytics"
                element={
                  <ProtectedRoute>
                    <Analytics />
                  </ProtectedRoute>
                }
              />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/cookies" element={<Cookies />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              <Route
                path="/admin/*"
                element={
                  <ProtectedAdminRoute>
                    <Routes>
                      <Route path="/" element={<AdminDashboard />} />
                      <Route path="/users" element={<Users />} />
                    </Routes>
                  </ProtectedAdminRoute>
                }
              />
              <Route path="/profiles" element={<Profiles />} />
            </Routes>
            <Footer />
          </div>
        </AuthProvider>
      </ToastProvider>
    </Router>
  );
}

export default App;