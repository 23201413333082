import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User, Calendar, Upload, Loader2 } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useToast } from '../context/ToastContext';
import { ImageDropzone } from '../components/ImageDropzone';
import { updateProfile, getProfile } from '../lib/api/profile';
import { ChangePasswordForm } from '../components/profile/ChangePasswordForm';
import { ProfileCard } from '../components/profile/ProfileCard';
import { format } from 'date-fns';

const GENDER_OPTIONS = ['Male', 'Female', 'Non-binary', 'Prefer not to say'] as const;

export const Profile: React.FC = () => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    full_name: '',
    age: '',
    gender: '',
    avatarUrl: '',
    bio: '',
    location: '',
    date_of_birth: '',
    privacy_settings: {
      profile_visibility: 'public',
      email_visibility: 'private'
    }
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    const loadProfile = async () => {
      if (!user) return;
      
      try {
        const profile = await getProfile(user.id);
        setFormData({
          username: profile.username || '',
          full_name: profile.full_name || '',
          age: profile.age?.toString() || '',
          gender: profile.gender || '',
          avatarUrl: profile.avatar_url || '',
          bio: profile.bio || '',
          location: profile.location || '',
          date_of_birth: profile.date_of_birth || '',
          privacy_settings: profile.privacy_settings || {
            profile_visibility: 'public',
            email_visibility: 'private'
          }
        });
      } catch (error) {
        console.error('Error loading profile:', error);
        showToast('Failed to load profile data');
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, [user, showToast]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.username.trim()) {
      newErrors.username = 'Username is required';
    } else if (!/^[a-zA-Z0-9_]{3,20}$/.test(formData.username)) {
      newErrors.username = 'Username must be 3-20 characters and contain only letters, numbers, and underscores';
    }

    if (formData.full_name && formData.full_name.length > 50) {
      newErrors.full_name = 'Full name must be less than 50 characters';
    }

    const age = parseInt(formData.age);
    if (!formData.age) {
      newErrors.age = 'Age is required';
    } else if (isNaN(age) || age < 13 || age > 120) {
      newErrors.age = 'Age must be between 13 and 120';
    }

    if (!formData.gender) {
      newErrors.gender = 'Please select a gender';
    }

    if (formData.bio && formData.bio.length > 500) {
      newErrors.bio = 'Bio must be less than 500 characters';
    }

    if (formData.location && formData.location.length > 100) {
      newErrors.location = 'Location must be less than 100 characters';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    if (!validateForm()) return;

    setSaving(true);
    try {
      await updateProfile(user.id, {
        username: formData.username,
        full_name: formData.full_name,
        age: parseInt(formData.age),
        gender: formData.gender,
        avatar_url: formData.avatarUrl,
        bio: formData.bio,
        location: formData.location,
        date_of_birth: formData.date_of_birth,
        privacy_settings: formData.privacy_settings
      });
      showToast('Profile updated successfully!');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      showToast('Failed to update profile. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleImageUpload = (url: string) => {
    setFormData(prev => ({ ...prev, avatarUrl: url }));
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-primary-light flex items-center justify-center">
        <div className="text-center">
          <Loader2 className="w-8 h-8 animate-spin text-accent-red mx-auto" />
          <p className="mt-2 text-gray-600">Loading profile...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-primary-light py-12">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent mb-8">
          Profile Settings
        </h1>

        <div className="mb-8">
          <ProfileCard
            fullName={formData.full_name || user?.user_metadata?.full_name || 'User'}
            title={formData.username ? `@${formData.username}` : undefined}
            email={user?.email}
            avatarUrl={formData.avatarUrl}
            location={formData.location}
            bio={formData.bio}
          />
        </div>

        {!isEditing ? (
          <div className="flex justify-end">
            <button
              onClick={() => setIsEditing(true)}
              className="px-4 py-2 text-sm font-medium text-white bg-accent-red rounded-lg hover:bg-red-700"
            >
              Edit Profile
            </button>
          </div>
        ) : (
          <div className="bg-white rounded-2xl shadow-xl p-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            <ImageDropzone
              onImageUpload={handleImageUpload}
              existingImage={formData.avatarUrl}
              onRemoveImage={() => setFormData(prev => ({ ...prev, avatarUrl: '' }))}
            />

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Full Name
              </label>
              <input
                type="text"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                placeholder="Your full name"
              />
              {errors.full_name && (
                <p className="mt-1 text-sm text-red-600">{errors.full_name}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Username
              </label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                placeholder="Choose a username"
              />
              {errors.username && (
                <p className="mt-1 text-sm text-red-600">{errors.username}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Bio
              </label>
              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleChange}
                rows={4}
                className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                placeholder="Tell us about yourself"
              />
              {errors.bio && (
                <p className="mt-1 text-sm text-red-600">{errors.bio}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                placeholder="City, Country"
              />
              {errors.location && (
                <p className="mt-1 text-sm text-red-600">{errors.location}</p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Age
                </label>
                <input
                  type="number"
                  name="age"
                  value={formData.age}
                  onChange={handleChange}
                  min="13"
                  max="120"
                  className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                  placeholder="Your age"
                />
                {errors.age && (
                  <p className="mt-1 text-sm text-red-600">{errors.age}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Gender
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                >
                  <option value="">Select gender</option>
                  {GENDER_OPTIONS.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {errors.gender && (
                  <p className="mt-1 text-sm text-red-600">{errors.gender}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date of Birth
              </label>
              <input
                type="date"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleChange}
                className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
              />
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Privacy Settings</h3>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Profile Visibility
                </label>
                <select
                  name="privacy_settings.profile_visibility"
                  value={formData.privacy_settings.profile_visibility}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email Visibility
                </label>
                <select
                  name="privacy_settings.email_visibility"
                  value={formData.privacy_settings.email_visibility}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-lg border-2 border-gray-200 px-4 py-2 focus:border-accent-red focus:ring focus:ring-accent-red/20"
                >
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
              </div>
            </div>

            {user && (
              <div className="pt-4 border-t border-gray-200">
                <div className="flex items-center text-sm text-gray-500">
                  <Calendar className="w-4 h-4 mr-2" />
                  Account created: {format(new Date(user.created_at), 'PPP')}
                </div>
              </div>
            )}

            <div className="flex justify-end space-x-3 pt-6">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={saving}
                className="px-4 py-2 text-sm font-medium text-white bg-accent-red rounded-lg hover:bg-red-700 disabled:opacity-50 flex items-center"
              >
                {saving ? (
                  <>
                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                    Saving...
                  </>
                ) : (
                  'Save Changes'
                )}
              </button>
            </div>
          </form>
        </div>
        )}
        
        <div className="mt-8">
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  );
};