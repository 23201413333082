import { supabase } from '../supabase';
import type { Event } from '../../types/event';

export async function createEvent(eventData: Omit<Event, 'id' | 'userId'>, userId: string): Promise<Event> {
  if (!userId) {
    throw new Error('User ID is required to create an event');
  }

  try {
    const { data, error } = await supabase
      .from('events')
      .insert([{
        title: eventData.title,
        description: eventData.description,
        date: eventData.date.toISOString(),
        location: eventData.location,
        address_street: eventData.addressStreet,
        address_city: eventData.addressCity,
        address_state: eventData.addressState,
        address_zip: eventData.addressZip,
        organizer: eventData.organizer,
        category: eventData.category,
        image_url: eventData.imageUrl || null,
        user_id: userId
      }])
      .select()
      .single();

    if (error) {
      console.error('Database error:', error);
      throw new Error('Failed to create event. Please try again.');
    }

    if (!data) {
      throw new Error('No data returned after creating event');
    }

    return {
      ...eventData,
      id: data.id,
      userId: userId,
      imageUrl: data.image_url,
      backgroundColor: data.background_color,
      date: new Date(data.date)
    };
  } catch (error: any) {
    console.error('Create event error:', error);
    const message = error?.message || 'Failed to create event. Please try again.';
    throw new Error(message);
  }
}

export async function updateEvent(event: Event): Promise<void> {
  if (!event.userId) {
    throw new Error('User ID is required to update an event');
  }

  try {
    const { error } = await supabase
      .from('events')
      .update({
        title: event.title,
        description: event.description,
        date: event.date.toISOString(),
        location: event.location,
        address_street: event.addressStreet,
        address_city: event.addressCity,
        address_state: event.addressState,
        address_zip: event.addressZip,
        organizer: event.organizer,
        category: event.category,
        image_url: event.imageUrl,
      })
      .eq('id', event.id)
      .eq('user_id', event.userId);

    if (error) {
      if (error.code === '23505') {
        throw new Error('An event with this title already exists');
      }
      throw new Error(`Failed to update event: ${error.message}`);
    }
  } catch (error) {
    const message = error instanceof Error ? error.message : 'An unexpected error occurred while updating the event';
    throw new Error(message);
  }
}

export async function deleteEvent(eventId: string, userId: string): Promise<void> {
  if (!userId) {
    throw new Error('User ID is required to delete an event');
  }

  try {
    const { error } = await supabase
      .from('events')
      .delete()
      .eq('id', eventId)
      .eq('user_id', userId);

    if (error) {
      if (error.code === '23503') {
        throw new Error('Cannot delete event: it has associated records');
      }
      throw new Error(`Failed to delete event: ${error.message}`);
    }
  } catch (error) {
    const message = error instanceof Error ? error.message : 'An unexpected error occurred while deleting the event';
    throw new Error(message);
  }
}

export async function getEvents(): Promise<Event[]> {
  try {
    const { data, error } = await supabase
      .from('events')
      .select('*')
      .order('date', { ascending: true });

    if (error) {
      throw new Error(`Failed to fetch events: ${error.message}`);
    }

    return (data || []).map(event => ({
      id: event.id,
      title: event.title,
      description: event.description,
      date: new Date(event.date),
      location: event.location,
      addressStreet: event.address_street,
      addressCity: event.address_city,
      addressState: event.address_state,
      addressZip: event.address_zip,
      organizer: event.organizer,
      category: event.category,
      imageUrl: event.image_url || null,
      userId: event.user_id
    }));
  } catch (error) {
    const message = error instanceof Error ? error.message : 'An unexpected error occurred while fetching events';
    throw new Error(message);
  }
}