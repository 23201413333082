import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSmoothScroll = () => {
  const navigate = useNavigate();

  const scrollToTop = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    const href = e.currentTarget.getAttribute('href');
    if (!href?.startsWith('/')) return;

    e.preventDefault();

    // First scroll smoothly to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    // Then navigate to the new route after scroll completes
    setTimeout(() => {
      navigate(href);
    }, 500);
  }, [navigate]);

  return { scrollToTop };
};