import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, X, Image as ImageIcon } from 'lucide-react';
import { uploadEventImage, deleteEventImage } from '../lib/api/storage';
import { useToast } from '../context/ToastContext';

interface ImageDropzoneProps {
  onImageUpload: (url: string) => void;
  existingImage?: string;
  onRemoveImage?: () => void;
}

export const ImageDropzone: React.FC<ImageDropzoneProps> = ({
  onImageUpload,
  existingImage,
  onRemoveImage
}) => {
  const { showToast } = useToast();

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    try {
      const publicUrl = await uploadEventImage(file);
      onImageUpload(publicUrl);
      showToast('Image uploaded successfully!');
    } catch (error) {
      console.error('Error uploading image:', error);
      showToast('Failed to upload image. Please try again.');
    }
  }, [onImageUpload, showToast]);

  const handleRemoveImage = async () => {
    if (!existingImage || !onRemoveImage) return;

    try {
      await deleteEventImage(existingImage);
      onRemoveImage();
      showToast('Image removed successfully!');
    } catch (error) {
      console.error('Error removing image:', error);
      showToast('Failed to remove image. Please try again.');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    },
    maxFiles: 1,
    multiple: false,
    maxSize: 10 * 1024 * 1024 // 10MB
  });

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">
        Event Image
      </label>
      
      {existingImage ? (
        <div className="relative rounded-lg overflow-hidden">
          <img
            src={existingImage}
            alt="Event preview"
            className="w-full h-48 object-cover"
          />
          <button
            onClick={handleRemoveImage}
            className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700 transition-colors"
            aria-label="Remove image"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
            ${isDragActive 
              ? 'border-accent-red bg-red-50' 
              : 'border-gray-300 hover:border-accent-red'}`}
        >
          <input {...getInputProps()} />
          <div className="space-y-3">
            <div className="flex justify-center">
              {isDragActive ? (
                <Upload className="w-10 h-10 text-accent-red" />
              ) : (
                <ImageIcon className="w-10 h-10 text-gray-400" />
              )}
            </div>
            <div className="text-sm text-gray-600">
              {isDragActive ? (
                <p>Drop the image here...</p>
              ) : (
                <>
                  <p className="font-medium">Drag & drop an image here, or click to select</p>
                  <p className="text-xs">PNG, JPG, GIF up to 10MB</p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};