import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export const Cookies: React.FC = () => {
  return (
    <div className="min-h-screen bg-primary-light dark:bg-dark-bg-primary py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <Link to="/" className="inline-flex items-center text-accent-red hover:text-red-700 mb-8">
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Home
        </Link>

        <div className="bg-white dark:bg-dark-surface-primary rounded-2xl shadow-xl p-8">
          <h1 className="text-4xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent mb-6">Cookie Policy</h1>
          
          <div className="prose dark:prose-invert max-w-none">
            <h2>What Are Cookies</h2>
            <p>
              Cookies are small text files that are stored on your device when you visit our website.
              They help us provide you with a better experience by remembering your preferences
              and enabling certain features.
            </p>

            <h2>How We Use Cookies</h2>
            <p>We use cookies for:</p>
            <ul>
              <li>Authentication and security</li>
              <li>Preferences and settings</li>
              <li>Analytics and performance</li>
              <li>Feature functionality</li>
            </ul>

            <h2>Types of Cookies We Use</h2>
            <ul>
              <li>Essential cookies: Required for basic site functionality</li>
              <li>Functional cookies: Remember your preferences</li>
              <li>Analytics cookies: Help us improve our services</li>
              <li>Marketing cookies: Used for targeted advertising</li>
            </ul>

            <h2>Managing Cookies</h2>
            <p>
              You can control and/or delete cookies as you wish. You can delete all cookies
              that are already on your computer and you can set most browsers to prevent
              them from being placed.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};