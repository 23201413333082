import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Users, Shield, Database, Settings, BarChart, 
  Activity, Bell, Key, Mail, Layers
} from 'lucide-react';

const menuItems = [
  { 
    label: 'Users', 
    icon: Users, 
    path: '/admin/users',
    badge: '23'
  },
  { 
    label: 'Security', 
    icon: Shield, 
    path: '/admin/security' 
  },
  { 
    label: 'Data Management', 
    icon: Database, 
    path: '/admin/data' 
  },
  { 
    label: 'Settings', 
    icon: Settings, 
    path: '/admin/settings' 
  },
  { 
    label: 'Analytics', 
    icon: BarChart, 
    path: '/admin/analytics' 
  },
  { 
    label: 'Monitoring', 
    icon: Activity, 
    path: '/admin/monitoring' 
  },
  { 
    label: 'Notifications', 
    icon: Bell, 
    path: '/admin/notifications',
    badge: '4'
  },
  { 
    label: 'Access Control', 
    icon: Key, 
    path: '/admin/access' 
  },
  { 
    label: 'Email Config', 
    icon: Mail, 
    path: '/admin/email' 
  },
  { 
    label: 'Integrations', 
    icon: Layers, 
    path: '/admin/integrations' 
  }
];

export const Sidebar: React.FC = () => {
  const location = useLocation();

  return (
    <aside className="fixed left-0 top-16 w-64 h-[calc(100vh-4rem)] bg-white dark:bg-dark-surface-primary border-r border-gray-200 dark:border-dark-border-primary overflow-y-auto">
      <nav className="p-4 space-y-1">
        {menuItems.map((item) => {
          const Icon = item.icon;
          const isActive = location.pathname === item.path;
          
          return (
            <Link
              key={item.path}
              to={item.path}
              className={`flex items-center px-4 py-3 rounded-lg transition-colors relative ${
                isActive 
                  ? 'bg-accent-red/10 text-accent-red' 
                  : 'text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-surface-secondary'
              }`}
            >
              <Icon className="w-5 h-5 mr-3" />
              <span className="font-medium">{item.label}</span>
              {item.badge && (
                <span className="absolute right-4 px-2 py-0.5 text-xs font-medium bg-accent-red text-white rounded-full">
                  {item.badge}
                </span>
              )}
            </Link>
          );
        })}
      </nav>
    </aside>
  );
};