export const categories = [
  'Arts & Culture',
  'Business & Professional',
  'Community & Civic',
  'Education & Learning',
  'Fashion & Beauty',
  'Film & Media',
  'Food & Drink',
  'Health & Wellness',
  'Hobbies & Special Interest',
  'Music & Performance',
  'Science & Technology',
  'Sports & Fitness',
  'Travel & Outdoor'
] as const;

export type EventCategory = typeof categories[number];