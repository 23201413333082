import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useEvents } from '../hooks/useEvents';
import { EventForm } from '../components/EventForm';
import type { Event } from '../types/event';
import { DashboardEventCard } from '../components/dashboard/DashboardEventCard';
import { DeleteConfirmDialog } from '../components/dashboard/DeleteConfirmDialog';
import { useToast } from '../context/ToastContext';
import { useSearchParams } from 'react-router-dom';
import { NotesGrid } from '../components/notes/NotesGrid';
import { Confetti } from '../components/Confetti';
import { DashboardCalendar } from '../components/dashboard/DashboardCalendar';

interface DashboardProps {
  showForm: boolean;
  setShowForm: (show: boolean) => void;
}

export const Dashboard: React.FC<DashboardProps> = ({
  showForm,
  setShowForm,
}) => {
  const { user } = useAuth();
  const { events, addEvent, updateEvent, deleteEvent, loading } = useEvents();
  const { showToast } = useToast();
  const [userEvents, setUserEvents] = useState<Event[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [editingEvent, setEditingEvent] = useState<Event | null>(null);
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search') || '';
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (user) {
      setUserEvents(events.filter(event => event.userId === user.id));
    }
  }, [events, user]);

  const filteredEvents = React.useMemo(() => {
    if (!searchQuery) return userEvents;
    
    const query = searchQuery.toLowerCase();
    return userEvents.filter(event =>
      event.title.toLowerCase().includes(query) ||
      event.description.toLowerCase().includes(query) ||
      event.location.toLowerCase().includes(query) ||
      event.organizer.toLowerCase().includes(query) ||
      event.category.toLowerCase().includes(query)
    );
  }, [userEvents, searchQuery]);

  const handleEventSubmit = async (eventData: Omit<Event, 'id' | 'userId'>) => {
    try {
      if (editingEvent) {
        const updatedEvent = {
          ...editingEvent,
          ...eventData,
        };
        await updateEvent(updatedEvent);
        showToast('Event updated successfully!');
        setEditingEvent(null);
      } else {
        const newEvent = await addEvent(eventData);
        showToast('Event posted successfully!');
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 2000);
      }
      setShowForm(false);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to save event. Please try again.';
      showToast(message);
      console.error('Event submission error:', error);
    }
  };

  const handleEditClick = (event: Event) => {
    setEditingEvent(event);
    setShowForm(true);
  };

  const handleDeleteClick = (event: Event) => {
    setSelectedEvent(event);
    setShowDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (selectedEvent) {
      try {
        await deleteEvent(selectedEvent.id);
        showToast('Event deleted successfully!');
        setShowDeleteDialog(false);
        setSelectedEvent(null);
      } catch (error) {
        console.error('Error deleting event:', error);
        showToast('Failed to delete event. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-primary-light">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-6">
          <button
            onClick={() => setShowForm(true)}
            className="inline-flex items-center px-4 py-2 bg-accent-red text-white rounded-lg hover:bg-red-700 transition-colors shadow-sm"
          >
            <Calendar className="w-5 h-5 mr-2" />
            Post New Event
          </button>
        </div>

        <Confetti trigger={showConfetti} />
        {showForm ? (
          <div className="bg-white dark:bg-dark-surface-primary shadow-xl rounded-xl p-4 sm:p-6 border border-gray-200 dark:border-dark-border-primary">
            <div className="flex items-center justify-between mb-4 sm:mb-6">
              <h2 className="text-2xl sm:text-3xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">
                {editingEvent ? 'Edit Event' : 'Post New Event'}
              </h2>
              <button
                onClick={() => {
                  setShowForm(false);
                  setEditingEvent(null);
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 transition-colors"
              >
                Cancel
              </button>
            </div>
            <EventForm 
              onSubmit={handleEventSubmit} 
              initialData={editingEvent || undefined}
            />
          </div>
        ) : (
          <>
            <DashboardCalendar />
            <div className="mt-8">
              <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6 gap-4">
                <h1 className="text-2xl sm:text-3xl font-black bg-gradient-to-r from-accent-red to-accent-blue bg-clip-text text-transparent">
                  Dashboard • My Events ({filteredEvents.length} of {userEvents.length})
                </h1>
              </div>

              {loading ? (
                <div className="text-center py-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-4 border-accent-red border-t-transparent mx-auto"></div>
                  <p className="mt-4 text-gray-600 dark:text-gray-400">Loading events...</p>
                </div>
              ) : userEvents.length === 0 ? (
                <div className="text-center py-12">
                  <Calendar className="w-16 h-16 text-accent-red mx-auto mb-4" />
                  <h2 className="text-xl font-semibold text-primary mb-2">No Events Yet</h2>
                  <p className="text-gray-600">
                    You haven't posted any events yet. Start sharing with your community!
                  </p>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                  {filteredEvents.map(event => (
                    <DashboardEventCard
                      key={event.id}
                      event={event}
                      onEdit={() => handleEditClick(event)}
                      onDelete={() => handleDeleteClick(event)}
                    />
                  ))}
                </div>
              )}
              <NotesGrid />
            </div>
          </>
        )}
      </div>

      <DeleteConfirmDialog
        isOpen={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
        eventTitle={selectedEvent?.title}
      />
    </div>
  );
};