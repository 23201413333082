import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEvents } from '../hooks/useEvents';
import { useToast } from '../context/ToastContext';
import { useAuth } from '../context/AuthContext';
import type { Event } from '../types/event';
import { Confetti } from '../components/Confetti';
import { EventFormSection } from '../components/home/EventFormSection';
import { EventListSection } from '../components/home/EventListSection';

interface HomeProps {
  showForm: boolean;
  setShowForm: (show: boolean) => void;
}

export const Home: React.FC<HomeProps> = ({
  showForm,
  setShowForm,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showToast } = useToast();
  const { user } = useAuth();
  const { events, addEvent, updateEvent, loading } = useEvents();
  const [showConfetti, setShowConfetti] = useState(false);
  
  const searchQuery = searchParams.get('search') || '';
  
  const filteredEvents = React.useMemo(() => {
    if (!searchQuery) return events;
    
    const query = searchQuery.toLowerCase();
    return events.filter(event => 
      event.title.toLowerCase().includes(query) ||
      event.description.toLowerCase().includes(query) ||
      event.location.toLowerCase().includes(query) ||
      event.organizer.toLowerCase().includes(query) ||
      event.category.toLowerCase().includes(query)
    );
  }, [events, searchQuery]);

  const handleEventSubmit = async (eventData: Omit<Event, 'id' | 'userId'>) => {
    try {
      if (!user) {
        throw new Error('Please sign in to post events');
      }

      const newEvent = await addEvent(eventData);
      setShowForm(false);
      setShowConfetti(true);
      showToast('Event created successfully!');
      setTimeout(() => setShowConfetti(false), 2000);
      navigate(`/event/${newEvent.id}`);
    } catch (error) {
      console.error('Error posting event:', error);
      if (error instanceof Error) {
        if (error.message === 'Please sign in to post events') {
          showToast(error.message);
          navigate('/auth');
        } else {
          showToast('Failed to post event. Please try again.');
        }
      }
    }
  };

  const handleEventUpdate = async (updatedEvent: Event) => {
    try {
      await updateEvent(updatedEvent);
      showToast('Event updated successfully!');
    } catch (error) {
      console.error('Error updating event:', error);
      showToast('Failed to update event. Please try again.');
    }
  };

  return (
    <main className="max-w-7xl mx-auto py-2 px-2 sm:py-6 sm:px-6 lg:px-8">
      <Confetti trigger={showConfetti} />
      {showForm && user ? (
        <EventFormSection
          onClose={() => setShowForm(false)}
          onSubmit={handleEventSubmit}
        />
      ) : (
        <EventListSection
          loading={loading}
          events={filteredEvents}
          searchQuery={searchQuery}
          onEventUpdate={handleEventUpdate}
        />
      )}
    </main>
  );
};